import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Container} from '@mui/system';

import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import Evaluation from '../Evaluation/Evaluation';
import EvaluationTheme from './EvaluationTheme';

import feedbackIcon from '../img/EvaluationLight.svg';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';

const ParentNewFeedbackScreen = () => {
  const theme = useTheme();

  return (
    <Evaluation
      pageHeader={
        <>
          <PageHeader
            color={theme.palette.feedbackColor}
            name={FEEDBACK_STRINGS.parent.feedbackTitle}
            icon={feedbackIcon}
            backTo="/feedback"
          />
          <Container maxWidth="md" sx={{pt: 2}}>
            <ParentCurrentYoungPersonSwitcher verb="leaving" />
          </Container>
        </>
      }
      EvaluationTheme={EvaluationTheme}
    />
  );
};

export default ParentNewFeedbackScreen;
