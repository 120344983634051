import React, {useState, useEffect} from 'react';
import {useParams, Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Collapse, Divider, Box, ButtonBase, Container, Link, Typography} from '@mui/material';

import {categoryIcons} from '../../Util/constants';
import {useYoungPerson} from '../../Parent/YoungPersonProvider';
import {useTransition} from '../TransitionProvider';
import KeyAction from '../KeyAction/KeyAction';
import PageHeader from '../../Util/PageHeader';
import TextTruncate from 'react-text-truncate';
import completeTickIcon from '../../img/complete-tick-primary.svg';

const CategoryKeyActionList = () => {
  const {youngPerson} = useYoungPerson();
  const {transitionState, getKeyActions} = useTransition();
  const {categoryId} = useParams();
  const theme = useTheme();
  const {transitionColor} = theme.palette;

  const [categoryName, setCategoryName] = useState(undefined);
  const [instructionsAreVisible, setInstructionsAreVisible] = useState(false);
  const [userHasHiddenCompletedActions, setUserHasHiddenCompletedActions] = useState(false);

  useEffect(() => {
    const init = async () => {
      await getKeyActions(youngPerson.id);
    };
    if (youngPerson.id) {
      init();
    }
  }, [youngPerson.id]);

  useEffect(() => {
    if (transitionState.categories) {
      const newCategoryName = transitionState.categories.find(
        (category) => category.id === parseInt(categoryId)
      ).name;
      setCategoryName(newCategoryName);
    }
  }, [transitionState.categories]);

  const getCategoryKeyActions = () => {
    if (!transitionState.keyActions || !transitionState.keyActions[categoryId]) {
      return [];
    }
    return transitionState.keyActions[categoryId];
  };

  const incompleteCategoryKeyActions = getCategoryKeyActions().filter(
    (youngPersonKeyAction) => !youngPersonKeyAction.completed
  );

  const completeCategoryKeyActions = getCategoryKeyActions().filter(
    (youngPersonKeyAction) => youngPersonKeyAction.completed
  );

  const shouldShowCompletedActions =
    !userHasHiddenCompletedActions && completeCategoryKeyActions.length > 0;

  return (
    <>
      <PageHeader
        color={transitionColor}
        name={categoryName}
        icon={categoryIcons[categoryName]}
        backTo="/transition-topics"
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <Typography variant="h2">Things to Do ({incompleteCategoryKeyActions.length})</Typography>
        <Divider sx={{mt: 1, mb: 2}} aria-hidden="true" />
        <TextTruncate
          line={instructionsAreVisible ? 0 : 2} // this component shows the first few words of the next line - if we want up to 3 lines visible, we must set this value to 2.
          truncateText="…"
          text={`Your 'to do' list will help you stay on track. 
              Don't try to do everything at once - move through the tasks at your own pace. 
              As you mark each action complete, the list will help you work out what to tackle next.`}
          textTruncateChild={
            <button
              className="primary-base button-link button-reset"
              onClick={() => setInstructionsAreVisible(true)}>
              show more
            </button>
          }
        />
        {instructionsAreVisible && (
          <button
            className="primary-base button-link button-reset"
            onClick={() => setInstructionsAreVisible(false)}>
            show less
          </button>
        )}
        {incompleteCategoryKeyActions.length ? (
          transitionState.keyActionsLoading || transitionState.transitionQuestionsLoading ? (
            <p>Loading...</p>
          ) : (
            incompleteCategoryKeyActions.map((youngPersonKeyAction) => (
              <KeyAction
                key={`key-action-${youngPersonKeyAction.id}`}
                youngPersonKeyAction={youngPersonKeyAction}
              />
            ))
          )
        ) : completeCategoryKeyActions.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              my: 3,
            }}>
            <Box
              component="img"
              src={completeTickIcon}
              alt=""
              width={42}
              height={42}
              sx={{mr: 2}}
            />
            <div>
              Congratulations, you’ve completed all the available tasks in this topic. Go to the{' '}
              <Link component={RouterLink} to="/">
                Home Screen
              </Link>{' '}
              to see what to do next!
            </div>
          </Box>
        ) : (
          <Typography paragraph>
            You haven't unlocked any tasks in this topic yet. Go to the Home Screen to see what to
            do next.
          </Typography>
        )}
        <ButtonBase
          focusRipple
          onClick={() => setUserHasHiddenCompletedActions(!userHasHiddenCompletedActions)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}>
          <Typography variant="h3" gutterBottom>
            Completed Tasks ({completeCategoryKeyActions.length})
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              fontSize: '1.4rem',
              transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.short,
              }),
              transform: shouldShowCompletedActions ? 'rotate(-180deg)' : 'rotate(0deg)',
            }}
          />
        </ButtonBase>
        <Divider sx={{mt: 1, mb: 2}} aria-hidden="true" />
        <Collapse in={shouldShowCompletedActions}>
          {transitionState.keyActionsLoading || transitionState.transitionQuestionsLoading ? (
            <div>Loading...</div>
          ) : (
            completeCategoryKeyActions.map((youngPersonKeyAction) => (
              <KeyAction
                key={`key-action-${youngPersonKeyAction.id}`}
                youngPersonKeyAction={youngPersonKeyAction}
              />
            ))
          )}
        </Collapse>
      </Container>
    </>
  );
};

export default CategoryKeyActionList;
