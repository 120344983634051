import React from 'react';
import {useTheme} from '@mui/material/styles';

import PageHeader from '../Util/PageHeader';
import PrivacyHeaderIcon from '../img/privacy-header.svg';
import {PARENT_HELPLINE_NUMBERS} from '../Util/constants';
import {Container, Link, Typography} from '@mui/material';

const ParentCrisisHelp = () => {
  const theme = useTheme();

  const {dark, contrastText} = theme.palette.primary;

  return (
    <>
      <PageHeader
        color={dark}
        name={'Emergency Information'}
        // TODO: replace icon
        icon={PrivacyHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <Typography paragraph>
          Your local authority will have an emergency social work services team. The number will be
          on your local authority website.
        </Typography>
        <Typography paragraph>
          The <span className="bold">Samaritans</span> helpline is{' '}
          <Link
            className="bold"
            href={`tel:${PARENT_HELPLINE_NUMBERS.samaritans}`}
            target="_blank"
            rel="noreferrer">
            {PARENT_HELPLINE_NUMBERS.samaritans}
          </Link>{' '}
          and is open 24 hours, 7 days a week. Calls can be made anytime, from any phone. All calls
          are confidential.
        </Typography>
        <Typography paragraph>
          The <span className="bold">Breathing Space</span> helpline is{' '}
          <Link
            className="bold"
            href={`tel:${PARENT_HELPLINE_NUMBERS.breathingSpace}`}
            target="_blank"
            rel="noreferrer">
            {PARENT_HELPLINE_NUMBERS.breathingSpace}
          </Link>{' '}
          and is open Weekdays: Monday-Thursday 6pm to 2am and Weekends: Friday 6pm-Monday 6am. All
          calls are confidential.
        </Typography>
      </Container>
    </>
  );
};

export default ParentCrisisHelp;
