import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Container} from '@mui/system';
import {Box, Typography} from '@mui/material';

import FeedbackHomeScreen from '../Evaluation/FeedbackHomeScreen';
import PageHeader from '../Util/PageHeader';
import {EVALUATION_PROMPT_THRESHOLD, FEEDBACK_STRINGS} from '../Util/constants';
import feedbackIcon from '../img/EvaluationLight.svg';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';

const FeedbackScreen = () => {
  const theme = useTheme();
  const {feedbackTitle, feedbackButtonTitle, feedbackHistoryTitle} = FEEDBACK_STRINGS.parent;
  return (
    <FeedbackHomeScreen
      pageHeader={
        <>
          <PageHeader
            color={theme.palette.feedbackColor}
            name={feedbackTitle}
            icon={feedbackIcon}
          />
          <Container maxWidth="md" sx={{py: 4}}>
            <ParentCurrentYoungPersonSwitcher verb="viewing" />
            <Box sx={{mt: 3}}>
              <Typography paragraph>
                Every {EVALUATION_PROMPT_THRESHOLD} days, Compass will ask about your transitions
                experience. This will help improve the process for parents and carers.
              </Typography>
              <Typography paragraph>
                You do not need to wait to be asked to tell us about your transitions experience.
                You can answer the questions at any time.
              </Typography>
            </Box>
          </Container>
        </>
      }
      feedbackColor={theme.palette.feedbackColor}
      feedbackButtonTitle={feedbackButtonTitle}
      feedbackHistoryTitle={feedbackHistoryTitle}
    />
  );
};

export default FeedbackScreen;
