import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {Container, Link, Typography} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import PrivacyHeaderIcon from '../img/privacy-header.svg';
import {CONTACT_EMAIL_ADDRESS} from '../Util/constants';
import ProcessingType from '../ProcessingType';
import PrivacyRight from '../PrivacyRight';

const PrivacyNotice = () => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  return (
    <>
      <PageHeader
        color={dark}
        name={'Privacy Notice'}
        icon={PrivacyHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <div className="home-section">
          <Typography variant="h2" gutterBottom>
            In brief: what happens to the information that you put into Compass
          </Typography>
          <ul>
            <li>
              Compass asks for feedback about you and your young person's experience of transition
            </li>
            <li>
              This will be used to help make transition better and to provide you with information
              relevant to the transition stage your young person is at
            </li>
            <li>
              Feedback will be shared with relevant local authorities in an aggregated and
              anonymised way – basically lumped together in a way to ensure that neither you nor
              your young person can be linked to it
            </li>
            <li>
              You will also find information here about how we look after your data and about the
              rights you have in relation to your data and how to use them
            </li>
          </ul>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom>
            In more detail: what happens to the information you put into Compass
          </Typography>
          <ul>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#app'}}>
                Who has created Compass and what is it for?
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#contact'}}>
                ARC contact details
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#types-of-info'}}>
                The types of personal information we collect and what we do with it
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#how-we-get'}}>
                How we get the personal information and why we ask for it
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#legal'}}>
                Legal information
              </Link>
            </li>
            <li>
              <Link
                component={HashLink}
                smooth
                to={{pathname: '/privacy', hash: '#do-not-provide'}}>
                What happens if you do not provide us with personal information?
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#look-after'}}>
                How we look after the information you provide
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#how-long'}}>
                How long we hold onto the information you provide
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#rights'}}>
                Your data protection rights
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#queries'}}>
                Queries or concerns
              </Link>
            </li>
          </ul>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="app">
            Who has created Compass and what is it for?
          </Typography>
          <Typography paragraph>
            Compass was created and is operated by ARC Scotland. ARC Scotland set up the Scottish
            Transitions Forum and has been working with the Scottish Government, Local Authorities
            and other organisations to improve the experience of children and young adults (14 to 25
            years) with additional support needs as they make the transition to young adult life.
            More about this project can be found{' '}
            <Link
              href="https://scottishtransitions.org.uk/7-principles-of-good-transitions/"
              target="_blank"
              rel="noreferrer">
              here
            </Link>
            .
          </Typography>
          <Typography paragraph>Compass is part of this project and has two aims:</Typography>
          <ul>
            <li>
              To provide parents or guardians of a young person with additional support needs with
              relevant information at each transition stage reached; and
            </li>
            <li>
              To share the insights provided by parents and guardians to help improve the transition
              experience.
            </li>
          </ul>
          <Typography paragraph>
            To do that we ask you to sign up to use Compass and then to answer various questions
            about your young person; about the transition journey; and to evaluate that journey.
            This will allow us to provide you with information that is relevant to the particular
            stage of transition that your young person is at.
          </Typography>
          <Typography paragraph>
            We will share a limited amount of profile information, transition information and
            evaluation information with relevant local authorities. Any information shared will be
            aggregated and anonymised which means it will be pooled together in a way that means it
            cannot be linked back to you or your young person.
          </Typography>
          <Typography paragraph>
            We will publish reports from time to time which will contain information obtained
            through Compass but which will never identify any individual. These reports will be
            publicly available with the same aim of improving the transition process.
          </Typography>
          <Typography paragraph>
            This privacy notice is here to help you understand what we are doing with your personal
            data; how we are keeping it safe; and to tell you about the rights that you have in
            relation to your personal data. We want you to feel comfortable that the personal
            information you are giving to us will be looked after.
          </Typography>
          <Typography paragraph>
            We have also created a short notice to provide information for your young person so that
            they understand what is happening to information about them. Please can you let your
            young person see this: {/* // TODO - this doesn't work */}
            <Link
              component={RouterLink}
              to="/privacy-info-young-people"
              target="_blank"
              rel="noreferrer">
              Privacy Information for Young People
            </Link>
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="contact">
            ARC contact details
          </Typography>
          <Typography paragraph>Name: Association for Real Change</Typography>
          <Typography paragraph>
            Address: ARC House, Marden Street, Chesterfield, Derbyshire S40 1JY
          </Typography>
          <Typography paragraph>
            Email:{' '}
            <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
              {CONTACT_EMAIL_ADDRESS}
            </Link>
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="types-of-info">
            The types of personal information we collect and what we do with it
          </Typography>
          <Typography paragraph>
            We collect and use the following information through Compass:
          </Typography>
          <ProcessingType
            name="Account Information"
            purpose="When you use Compass, you create a profile which includes your username and email
            address. This allows you to access your profile and to use Compass. We will send you
            password reminders or other essential account information by email. We will send you
            reminders by email or SMS if you ask us to."
            shared="Never"
          />
          <ProcessingType
            name="Profile of your young person"
            purpose=" We will ask you questions about your young person to allow us to provide you with advice
            specific to their needs. We will ask about your young person and any special needs that
            they have. This is likely to include more sensitive information about your young
            person’s health. We wish to reassure you that we require this information to provide you
            with relevant advice and to help to improve services for individuals with particular
            special needs. This information will be handled sensitively, carefully and will be kept
            secure."
            shared={
              <>
                <Typography paragraph>
                  Some of this information will be aggregated and shared in an anonymous way. This
                  information is:
                </Typography>
                <ul>
                  <li>the local authority area</li>
                  <li>the particular special needs of your young person</li>
                </ul>
              </>
            }
          />
          <ProcessingType
            name="Transition Information"
            purpose="We will ask you questions about the experience that you and your young person had throughout transition."
            shared="This will be shared in an anonymous and aggregated form"
          />
          <ProcessingType
            name="Evaluation Information"
            purpose="We will ask you to rate the experience that you and your young person had through transition."
            shared="This will be shared in an anonymous and aggregated form"
          />
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="how-we-get">
            How we get the personal information and why we ask for it
          </Typography>
          <Typography paragraph>
            The only source of information about you and your young person is you. We do not store
            any other information in Compass and we do not gather any more information in Compass.
          </Typography>
          <Typography paragraph>
            We only use cookies which are absolutely necessary to allow Compass to function and
            recognise you. A cookie is a small computer file of letters and numbers downloaded on to
            your device (e.g. PC or smartphone) when you access some websites.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="legal">
            Legal information
          </Typography>
          <Typography paragraph>
            Under the UK General Data Protection Regulation (GDPR), we need to have a lawful basis
            to use your information. We have set these out below.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Contractual Obligation
          </Typography>
          <Typography paragraph>
            We process Account Information to provide you with a user account and we ask questions
            to profile your young person so that we can ensure you get the right information at the
            right time about transition.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Legitimate Interest
          </Typography>
          <Typography paragraph>
            We are using the responses you provide to our questions about the transition process and
            your evaluation of that so that we can work with other organisations to improve the
            journey for parents and carers, and your young people. We believe that this is a good
            reason for us to ask personal questions but we have balanced this against your interests
            and those of your young person. Therefore, we only share aggregated and anonymised
            information.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Substantial Public Interest
          </Typography>
          <Typography paragraph>
            We are asking for some information about your young person which may include sensitive
            information about their health. Data protection law allows us to ask for this
            information as we are a not for profit body supporting young people with special needs.
            This information is only shared in an aggregated and anonymised form.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="do-not-provide">
            What happens if you do not provide us with personal information?
          </Typography>
          <Typography paragraph>
            We hope that the information in this notice reassures you about why we are asking for
            your personal data, but if you do not wish to provide the information we ask for,
            Compass will not work properly for you, and we may be unable to provide you with
            relevant information through Compass.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="look-after">
            How we look after the information you provide
          </Typography>
          <Typography paragraph>
            We work closely with a technology company called Wheelhouse who helped us to develop
            Compass and who keep it working. They also store the information gathered in Compass on
            their database on our behalf.
          </Typography>
          <Typography paragraph>
            Wheelhouse are legally called a processor which means that they provide us with the
            services we asked them to, under a legal contract we have with them. The terms of this
            contract mean that they cannot do anything with your information unless we have
            instructed them to do that. They will not share your personal information with any
            organisation apart from us or as instructed by us. They will hold it securely and only
            for as long as we instruct them to.
          </Typography>
          <Typography paragraph>
            Wheelhouse work with organisations like ARC to co-design websites and mobile
            applications with the aim of creating technology that is designed to work for everyone,
            including disabled and neurodiverse people.
          </Typography>
          <Typography paragraph>
            Wheelhouse uses a database run by Amazon Web Services which is hosted in the UK and
            other services to send your email and SMS reminders if requested. Their processes have
            been secured using cyber security expert advice.
          </Typography>
          <Typography paragraph>
            The data gathered by Compass is encrypted when being transferred between your device and
            the database. It is also encrypted as it is stored. Before information is shared with
            relevant local authorities, it is separated from your personal details, combined with
            other information and only this aggregated and anonymised information is shared.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="how-long">
            How long we hold onto the information you provide
          </Typography>
          <Typography paragraph>
            We keep your account open until you tell us to close it. Or if it is five years since
            you have used your account we will contact you using the email address you provided to
            us. Unless you tell us otherwise, we will then close your account shortly afterwards.
            Once your account is closed, your account information will be securely deleted from our
            database.
          </Typography>
          <Typography paragraph>
            We will retain the other information that you provided to us, but this will be
            anonymous. This will continue to assist us to improve the transition journey for young
            people in Scotland.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="rights">
            Your data protection rights
          </Typography>
          <Typography paragraph>Under data protection law, you have rights including:</Typography>
          <PrivacyRight
            name="Request access to your personal information"
            description={
              'Commonly known as a "data subject access request". This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.'
            }
          />
          <PrivacyRight
            name="Request correction of the personal information that we hold about you"
            description="This enables you to have any incomplete or inaccurate information we hold about you corrected."
          />
          <PrivacyRight
            name="Request erasure of your personal information"
            description="This enables you to ask us to delete or remove personal information where there is no
            good reason for us continuing to process it. You also have the right to ask us to delete
            or remove your personal information where you have exercised your right to object to
            processing (see below)."
          />
          <PrivacyRight
            name="Request the restriction of processing of your personal information"
            description="You would make this request if, for example, you want to establish its accuracy or the
            reason for processing it. You would generally ask for this restriction when you are
            asking for other information."
          />
          <PrivacyRight
            name="Your right to data portability"
            description="You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances."
          />
          <PrivacyRight
            name="Object to the processing of your personal information"
            description={
              <>
                <Typography paragraph>
                  If our lawful basis (see{' '}
                  <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#legal'}}>
                    above
                  </Link>
                  ) for using your data is because we believe we have a legitimate interest, you can
                  object to us using your data at any time and we will consider whether we can
                  continue to use it.
                </Typography>
                <Typography paragraph>
                  You do not have to pay any charge for exercising your rights. If you make a
                  request, we will do our best to comply with it, as soon as we can, but within one
                  month.
                </Typography>
              </>
            }
          />
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="queries">
            Queries or concerns
          </Typography>
          <Typography paragraph>
            Please contact us using the{' '}
            <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#contact'}}>
              contact details above
            </Link>{' '}
            to make a request or to raise any concerns you have about how the personal data gathered
            through Compass is being used.
          </Typography>
          <Typography paragraph>
            You can also contact the Information Commissioner’s Office if you have concerns and
            their contact details can be found here:{' '}
            <Link href="https://www.ico.org.uk/concerns" target="_blank" rel="noreferrer">
              www.ico.org.uk/concerns
            </Link>
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default PrivacyNotice;
