import React from 'react';
import {Avatar, Box, Button, Typography, useMediaQuery, useTheme} from '@mui/material';

import {useYoungPerson} from './YoungPersonProvider';
import {getYoungPersonDisplayName} from '../Util/helpers';

const ParentCurrentYoungPersonSwitcher = ({noun = 'feedback', verb = 'leaving'}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));
  const {youngPerson, youngPeople} = useYoungPerson();
  return youngPerson ? (
    <Box
      sx={{
        color: 'mono.main',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('sm')]: {
          alignItems: 'center',
          flexDirection: 'row',
        },
        borderRadius: 5,
        backgroundColor: '#e5f6fe',
        padding: 1,
        mb: 1.5,
      }}>
      {isMd && <Avatar fontSize="small" sx={{mr: 1}} />}
      <Typography sx={{flex: 1, mr: 1}}>
        You are currently {verb} {noun} for{' '}
        <Typography
          sx={{fontWeight: 'bold'}}
          variant="body1"
          component="span"
          data-testid="current-yp-name">
          {getYoungPersonDisplayName(youngPerson)}
        </Typography>
      </Typography>
      {youngPeople.length > 1 && (
        <Button
          sx={{
            color: 'mono.main',
            alignSelf: 'flex-end',
            [theme.breakpoints.up('sm')]: {
              alignSelf: 'center',
            },
          }}
          size="small"
          variant="outlined"
          onClick={() => document.getElementById('menu').click()}>
          Change {isLg && ' Young Person'}
        </Button>
      )}
    </Box>
  ) : null;
};

export default ParentCurrentYoungPersonSwitcher;
