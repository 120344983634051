import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, Typography} from '@mui/material';

import {useTransition} from './TransitionProvider';
import {useYoungPerson} from '../Parent/YoungPersonProvider';
import TransitionTimeline from './TransitionTimeline';
import CompleteStars from '../img/complete-stars.svg';

const StageCompleteScreen = () => {
  const navigate = useNavigate();
  const {youngPerson} = useYoungPerson();
  const {putStageTransition, stageDates, nextStage} = useTransition();
  const currentStage = youngPerson.id ? youngPerson.stage : null;
  const handleMoveToNextStage = async () => {
    const result = await putStageTransition(youngPerson.id);
    if (result === true) {
      navigate('/home');
    }
  };
  return (
    <>
      <Container maxWidth="md" sx={{textAlign: 'center', py: 6}}>
        <Box component="img" src={CompleteStars} sx={{mb: 3}} />
        <Typography variant="h1" gutterBottom>
          Stage Complete
        </Typography>
        {currentStage && (
          <>
            <Typography paragraph>
              You have completed stage <b>{currentStage.name}</b> in the transitions process.
            </Typography>
            <Typography paragraph>
              Have a look at your predicted timeline below to see what’s next.
            </Typography>
          </>
        )}
      </Container>
      <Box sx={{backgroundColor: 'primary.pale', py: 6}}>
        <Container maxWidth="md">
          <TransitionTimeline
            stageDates={stageDates}
            highlightedStage={nextStage}
            highlightText="Next Stage"
          />
        </Container>
      </Box>
      <Container maxWidth="md" sx={{textAlign: 'center', py: 6, mb: 2}}>
        <Typography variant="h2" gutterBottom>
          What's Next
        </Typography>
        {nextStage && (
          <>
            <Typography paragraph>
              You are about to move into stage <b>{nextStage.name}</b> in the transitions process.
            </Typography>
            <Typography paragraph>
              You will now have new questions to answer which will generate new things for you to
              do.
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button variant="contained" color="secondary" onClick={handleMoveToNextStage}>
                Move to Next Stage
              </Button>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};
export default StageCompleteScreen;
