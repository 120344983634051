import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {SentryRoutes} from '../Sentry';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import NavBar from './ParentNavBar';
import UnauthenticatedNavBar from '../Nav/UnauthenticatedNavBar';

import {useAuth} from '../Auth/AuthProvider';
import useDialogQuery from '../Util/useDialogQuery';
import {EvaluationProvider} from '../Evaluation/EvaluationProvider';
import {TransitionProvider} from '../Transition/TransitionProvider';
import {OnboardingProvider} from '../Onboarding/OnboardingProvider';
import {usePageTracking, MATOMO_COMPASS_APPLICATIONS} from '../MatomoAnalytics';
import {DIALOG_URL_PARAMS} from '../Util/constants';

import parentLogo from '../img/logo/logo_parent.svg';
import parentLogoDark from '../img/logo/logo_parent_dark.svg';

import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import ParentLandingPage from './ParentLandingPage';
import Login from '../Auth/Login';
import ParentHome from './ParentHome';
import ParentRegisterScreen from './ParentRegisterScreen';
import ParentNewFeedbackScreen from './ParentNewFeedbackScreen';
import FeedbackScreen from './FeedbackScreen';
import CompletedEvaluationScreen from './ParentCompletedEvaluationScreen';
import EvaluationHistoryScreen from './EvaluationHistoryScreen';
import PreviousEvaluation from './PreviousEvaluation';
import CategoryKeyActionList from '../Transition/Categories/CategoryKeyActionList';
import TransitionQuestions from '../Transition/TransitionQuestions/TransitionQuestions';
import GlossaryDialog from '../Glossary/GlossaryDialog';
import Account from './ParentAccount';
import DeleteAccount from '../Account/DeleteAccount';
import EditAccountScreen from '../Account/EditAccountScreen';
import StageCompleteScreen from '../Transition/StageCompleteScreen';
import TransitionTopicsScreen from '../Transition/TransitionTopicsScreen';
import PrivacyNotice from './PrivacyNotice';
import PrivacyInfoYoungPeople from './PrivacyInfoYoungPeople';
import ContactUs from '../ContactUs';
import ParentYourPersonalDataScreen from './ParentYourPersonalDataScreen';
import ParentEvaluationPromptDialog from './ParentEvaluationPromptDialog';
import ParentEvaluationAbout from './ParentEvaluationAbout';
import ParentOnboarding from './Onboarding/ParentOnboarding';
import ParentCrisisHelp from './ParentCrisisHelp';
import FullTimeline from '../Transition/FullTimeline';
import CookieConsentBanner from '../Util/CookieConsentBanner';

import ParentEvaluationOtherComments from './ParentEvaluationOtherComments';
import ParentCatchingUp from './ParentCatchingUp';
import YoungPersonProvider from './YoungPersonProvider';
import YoungPerson from './YoungPerson';
import EditYoungPersonScreen from './EditYoungPersonScreen';
import StoreRedirectUrlForAuth from '../Util/StoreRedirectUrlForAuth';
import AppBannerMessage from '../Util/AppBannerMessage';

const queryClient = new QueryClient();

const App = () => {
  const {authState} = useAuth();
  usePageTracking(MATOMO_COMPASS_APPLICATIONS.PARENT);

  return (
    <>
      <AppBannerMessage />
      {authState.isCheckingAuth ? (
        <div>Loading</div>
      ) : authState.isAuthenticated ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
      <CookieConsentBanner />
    </>
  );
};

const AuthenticatedApp = () => {
  const {dialogQuery} = useDialogQuery();
  return (
    <QueryClientProvider client={queryClient}>
      <EvaluationProvider EvaluationPromptDialog={ParentEvaluationPromptDialog}>
        <YoungPersonProvider>
          <NavBar />
          <OnboardingProvider Onboarding={ParentOnboarding}>
            <TransitionProvider>
              <main>
                <SentryRoutes>
                  <Route path="/" exact element={<ParentHome />} />
                  <Route path="/:categoryId/action-list" element={<CategoryKeyActionList />} />
                  <Route exact path="/feedback" element={<FeedbackScreen />} />
                  <Route exact path="/feedback/about" element={<ParentEvaluationAbout />} />
                  <Route exact path="/feedback/new" element={<ParentNewFeedbackScreen />} />
                  <Route path="/feedback/comments" element={<ParentEvaluationOtherComments />} />
                  <Route path="/feedback/complete" element={<CompletedEvaluationScreen />} />
                  <Route exact path="/feedback/history" element={<EvaluationHistoryScreen />} />
                  <Route path="/feedback/history/:evaluationId" element={<PreviousEvaluation />} />
                  <Route path="/transition-topics" element={<TransitionTopicsScreen />} />
                  <Route path="/transition-questions" element={<TransitionQuestions />} />
                  <Route path="/transition-timeline" element={<FullTimeline />} />
                  <Route path="/catching-up" element={<ParentCatchingUp />} />
                  <Route exact path="/my-account" element={<Account />} />
                  <Route path="/my-account/delete" element={<DeleteAccount />} />
                  <Route path="/my-account/edit/*" element={<EditAccountScreen />} />
                  <Route path="/young-person/:youngPersonID/" element={<YoungPerson />} />
                  <Route
                    path="/young-person/:youngPersonID/edit/*"
                    element={<EditYoungPersonScreen />}
                  />
                  <Route path="/stage-complete" element={<StageCompleteScreen />} />
                  <Route path="/privacy" element={<PrivacyNotice />} />
                  <Route path="/privacy-info-young-people" element={<PrivacyInfoYoungPeople />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/crisis" element={<ParentCrisisHelp />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </SentryRoutes>
              </main>
            </TransitionProvider>
          </OnboardingProvider>
          {dialogQuery === DIALOG_URL_PARAMS.GLOSSARY && <GlossaryDialog />}
        </YoungPersonProvider>
      </EvaluationProvider>
    </QueryClientProvider>
  );
};

const UnauthenticatedApp = () => {
  return (
    <>
      <UnauthenticatedNavBar logoLight={parentLogo} logoDark={parentLogoDark} />
      <main>
        <SentryRoutes>
          <Route exact path="/" element={<ParentLandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/your-data" element={<ParentYourPersonalDataScreen />} />
          <Route path="/register" element={<ParentRegisterScreen />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/privacy" element={<PrivacyNotice />} />
          <Route path="/privacy-info-young-people" element={<PrivacyInfoYoungPeople />} />
          <Route path="*" element={<StoreRedirectUrlForAuth />} />
        </SentryRoutes>
      </main>
    </>
  );
};

export default App;
