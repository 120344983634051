import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container, Link, Typography} from '@mui/material';

import {useTransition} from '../Transition/TransitionProvider';
import PageHeader from '../Util/PageHeader';
import {sectionIcons} from '../Util/constants';
import KeyAction from '../Transition/KeyAction/KeyAction';
import {useYoungPerson} from './YoungPersonProvider';

const ParentCatchingUp = () => {
  const {youngPerson} = useYoungPerson();
  const theme = useTheme();
  const {transitionColor} = theme.palette;
  const {transitionState, incompleteKeyActionsFromPreviousStages, getKeyActions} = useTransition();

  useEffect(() => {
    const init = async () => {
      // Refresh transition state information every time we load this screen
      await getKeyActions(youngPerson.id);
    };
    if (youngPerson.id) {
      init();
    }
  }, [youngPerson.id]);

  return (
    <>
      <PageHeader
        color={transitionColor}
        name={'Catching Up'}
        icon={sectionIcons.transitionTopicsIcon}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        {transitionState.keyActionsLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Typography paragraph>
              You've joined Compass in Stage {youngPerson?.stage?.index + 1 ?? ''} (
              {youngPerson?.stage?.name}), these are important things to do from previous stages
              that should be completed before moving on. You can explore them in any order you like.
            </Typography>
            <Typography paragraph>
              You'll be able to move on when you've completed all of these actions.
            </Typography>
            <Typography paragraph>
              If you feel these actions don't apply to your situation, simply mark them as completed
              to proceed.
            </Typography>
            {incompleteKeyActionsFromPreviousStages.length > 0 ? (
              incompleteKeyActionsFromPreviousStages.map((youngPersonKeyAction) => (
                <KeyAction
                  key={`key-action-${youngPersonKeyAction.id}`}
                  youngPersonKeyAction={youngPersonKeyAction}
                />
              ))
            ) : (
              <Typography>
                You're all caught up! You can return to the{' '}
                <Link component={RouterLink} to="/">
                  home screen
                </Link>{' '}
                to find out what to do next.
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ParentCatchingUp;
