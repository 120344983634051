import React from 'react';
import {useTheme} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate} from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  Box,
  Container,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import {useAuth} from '../Auth/AuthProvider';
import MyAccountDetails from '../Account/MyAccountDetails';
import MyAccountCookieToggle from '../Util/MyAccountCookieToggle';
import {useYoungPerson} from './YoungPersonProvider';
import {MONTHS} from '../Util/constants';

const Account = () => {
  const navigate = useNavigate();
  const {authState} = useAuth();
  const theme = useTheme();
  const {youngPeople} = useYoungPerson();
  const {dark, contrastText} = theme.palette.primary;
  // Ensure we've loaded the user before displaying
  const hasLoaded = authState.user.id !== undefined;
  return hasLoaded ? (
    <>
      <PageHeader
        color={dark}
        name={'My Account'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <Typography paragraph>
          Here is all the personal information you shared with Compass when you signed up. You can
          change this at any time.
        </Typography>
        <Box sx={{mb: 3}}>
          <Typography variant="h3" component="h2" gutterBottom>
            My Information
          </Typography>
          <MyAccountDetails user={authState.user} />
        </Box>
        <Box sx={{mb: 3}}>
          <Typography variant="h3" component="h2" gutterBottom>
            My Young People
          </Typography>
          <List>
            {youngPeople.map((eachYoungPerson) => (
              <ListItemButton
                divider
                key={`young-person-${eachYoungPerson.id}`}
                onClick={() => {
                  navigate(`/young-person/${eachYoungPerson.id}`);
                }}>
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    eachYoungPerson.nickname ??
                    `${MONTHS[eachYoungPerson.birth_month].slice(0, 3)} ${
                      eachYoungPerson.birth_year
                    }`
                  }
                />
                <ArrowForwardIosIcon />
              </ListItemButton>
            ))}
          </List>
        </Box>
        <MyAccountCookieToggle />
      </Container>
    </>
  ) : (
    <div className="center-content">
      <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress size={100} color="secondary" />
      </div>
    </div>
  );
};

export default Account;
