import React, {useState, useEffect} from 'react';
import {Box, Typography} from '@mui/material';

import {useOnboarding} from '../../Onboarding/OnboardingProvider';
import TransitionTimeline from '../../Transition/TransitionTimeline';
import predictedTimelineIcon from '../../img/clock.svg';

const PredictedTimelinePage = ({
  expectedLeavingDate,
  currentStage,
  setCurrentStage,
  showGlossaryLinks = false,
}) => {
  const {calculateTransitionStageDates, calculateCurrentTransitionStage} = useOnboarding();
  // TODO: consider renaming 'stageDates' - it doesn't just hold dates, it holds entire stage objects
  const [stageDates, setStageDates] = useState(undefined);

  useEffect(() => {
    if (expectedLeavingDate && expectedLeavingDate.term && expectedLeavingDate.year) {
      calculateTransitionStageDates(expectedLeavingDate.term, expectedLeavingDate.year).then(
        (transitionStageDates) => setStageDates(transitionStageDates)
      );
      if (setCurrentStage) {
        calculateCurrentTransitionStage(expectedLeavingDate.term, expectedLeavingDate.year).then(
          (currentTransitionStage) => setCurrentStage(currentTransitionStage)
        );
      }
    }
  }, [expectedLeavingDate]);

  return (
    <Box sx={{textAlign: 'center'}}>
      <Box component="img" src={predictedTimelineIcon} sx={{height: 136, width: '100%', mb: 2}} />
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          display: 'inline-block',
          backgroundColor: 'primary.dark',
          color: 'white',
          px: 2,
          py: 4,
          borderRadius: 2,
          mb: 4,
        }}>
        Predicted Timeline
      </Typography>
      {setCurrentStage !== undefined ? (
        <Box sx={{mb: 4, px: 4}}>
          <Typography paragraph>
            Compass has worked out your Transition Stage based on your young person's age, school
            year and expected leaving date.
          </Typography>
          <Typography paragraph>
            If you think this timeline looks wrong, click "Previous" to select a different expected
            leaving date.
          </Typography>
        </Box>
      ) : null}
      <TransitionTimeline
        stageDates={stageDates}
        highlightedStage={currentStage}
        highlightText="Current"
        showGlossaryLinks={showGlossaryLinks}
      />
    </Box>
  );
};

export default PredictedTimelinePage;
