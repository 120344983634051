import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Button, Container, Typography, Link} from '@mui/material';

import {useYoungPerson} from './YoungPersonProvider';
import {useEvaluation} from '../Evaluation/EvaluationProvider';
import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import feedbackIcon from '../img/EvaluationLight.svg';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';

const ParentEvaluationAbout = () => {
  const navigate = useNavigate();
  const {youngPerson} = useYoungPerson();
  const {isLoading, checkHasActiveEvaluation, createNewEvaluation} = useEvaluation();
  const theme = useTheme();
  const {feedbackColor} = theme.palette;

  const {feedbackTitle, feedbackButtonTitle} = FEEDBACK_STRINGS.parent;

  const handleContinue = async () => {
    const hasExistingEvaluation = await checkHasActiveEvaluation();
    if (!hasExistingEvaluation) {
      await createNewEvaluation(youngPerson.id);
    }
    navigate('/feedback/new');
  };

  return (
    <>
      <PageHeader
        color={feedbackColor}
        name={feedbackTitle}
        icon={feedbackIcon}
        backTo="/feedback"
      />
      <Container maxWidth="md" sx={{py: 3}}>
        <ParentCurrentYoungPersonSwitcher verb="leaving" />
        <Typography variant="h2" gutterBottom sx={{mt: 4}}>
          {feedbackButtonTitle}
        </Typography>
        <Typography paragraph>
          Please answer as honestly as you can and include both good and bad experiences.
        </Typography>
        <Typography paragraph>
          No one will be able to identify you or your family from your responses. They are added to
          other answers and shared with your local authority to improve transitions for everyone.
          Please read our{' '}
          <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
            Privacy Notice
          </Link>{' '}
          to find out how your data is used.
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          disabled={isLoading}
          onClick={handleContinue}
          style={{float: 'right'}}>
          Continue
        </Button>
      </Container>
    </>
  );
};

export default ParentEvaluationAbout;
