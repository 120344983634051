import React, {useState} from 'react';
import DoneIcon from '@mui/icons-material/Done';
import TextTruncate from 'react-text-truncate';
import {useTheme} from '@mui/material/styles';
import {Box, Card, CardActions, CardContent, CardHeader, Link, Typography} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import {useYoungPerson} from '../../Parent/YoungPersonProvider';
import {useTransition} from '../TransitionProvider';
import GlossaryAnnotatedText from '../../Util/GlossaryAnnotatedText';

const KeyAction = ({youngPersonKeyAction}) => {
  const {key_action: keyAction} = youngPersonKeyAction;
  const {key_action_links: keyActionsLinks} = keyAction;

  const {youngPerson} = useYoungPerson();
  const {updateKeyActionCompletedStatus} = useTransition();
  const theme = useTheme();
  const {transitionColor} = theme.palette;

  const [isUpdating, setIsUpdating] = useState(false);
  const [fullDescriptionIsVisible, setFullDescriptionIsVisible] = useState(false);

  const handleCheckboxChange = async () => {
    setIsUpdating(true);
    await updateKeyActionCompletedStatus(
      youngPerson.id,
      youngPersonKeyAction,
      !youngPersonKeyAction.completed
    );
    setIsUpdating(false);
  };

  return (
    <Card
      sx={{
        border: `1px solid ${transitionColor}`,
        ...(youngPersonKeyAction.completed
          ? {backgroundColor: transitionColor, color: 'mono.main'}
          : {}),
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        padding: 1,
        my: 2,
      }}>
      <CardHeader
        title={keyAction.name}
        avatar={youngPersonKeyAction.completed ? <DoneIcon color="secondary" /> : null}
        titleTypographyProps={{variant: 'h3'}}
      />
      <CardContent sx={{flex: 1, py: 0}}>
        {!fullDescriptionIsVisible ? (
          <TextTruncate
            line={2}
            truncateText="…"
            text={keyAction.description}
            containerClassName="body2"
            textTruncateChild={
              <button
                style={{fontWeight: 'bold'}}
                className="primary-base button-link button-reset "
                onClick={() => {
                  setFullDescriptionIsVisible(true);
                }}>
                show more
              </button>
            }
          />
        ) : (
          <>
            <GlossaryAnnotatedText text={keyAction.description} />
            <button
              style={{marginLeft: 5, fontWeight: 'bold'}}
              className="primary-base button-link button-reset"
              onClick={() => {
                setFullDescriptionIsVisible(false);
              }}>
              show less
            </button>
          </>
        )}
        <Box sx={{my: 2}}>
          <Typography variant="h5" gutterBottom>
            Links:
          </Typography>
          {keyActionsLinks.map((keyActionLink) => (
            <Typography gutterBottom key={`ka-link-${keyActionLink.id}`}>
              <Link href={keyActionLink.url} target="_blank" rel="noreferrer">
                - {keyActionLink.title !== '' ? keyActionLink.title : keyActionLink.url}
              </Link>
            </Typography>
          ))}
        </Box>
      </CardContent>
      <CardActions sx={{justifyContent: 'flex-end'}}>
        <LoadingButton
          loading={isUpdating}
          variant={youngPersonKeyAction.completed ? 'outlined' : 'contained'}
          sx={{
            ...(youngPersonKeyAction.completed
              ? {backgroundColor: transitionColor, color: 'mono.main'}
              : {backgroundColor: 'secondary.main'}),
          }}
          onClick={handleCheckboxChange}>
          {youngPersonKeyAction.completed ? 'Mark as Incomplete' : 'Mark as Complete'}
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default KeyAction;
