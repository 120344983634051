import React from 'react';
import {Typography} from '@mui/material';

import {PARENT_FEEDBACK_RATING_SCALE} from '../Util/constants';

const PreviousEvaluationQuestionResponse = ({response}) => {
  const RESPONSE_RATING_MAP = PARENT_FEEDBACK_RATING_SCALE.find(
    (ratingValue) => ratingValue.value === response.response_rating
  );
  const {label: responseKey, value: responseValue} = RESPONSE_RATING_MAP;
  const responseString = `${responseValue} - ${responseKey}`;

  return (
    <div className="eval-question">
      <div className="body1 eval-question-bullet primary-base">&#x2022;</div>
      <div className="eval-question-content">
        <Typography variant="body2" className="italic">
          {response.evaluation_question.title}
        </Typography>
        <div className="eval-item">
          <Typography variant="body2">
            You selected{' '}
            <span role="text" aria-label={responseString} className="bold">
              {responseString}
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PreviousEvaluationQuestionResponse;
