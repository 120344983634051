import React, {useState} from 'react';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import {useOnboarding} from '../../Onboarding/OnboardingProvider';

const SchoolTypePage = ({hasLeftSchool, schoolType, setSchoolType}) => {
  const [showOtherSchoolTypeInput, setShowOtherSchoolTypeInput] = useState(false);
  const {onboardingState} = useOnboarding();

  return (
    <>
      <h1 className="header-4 auth-text">{`What type of school ${
        hasLeftSchool ? `did` : `does`
      } your young person attend?`}</h1>
      <div className="body2 auth-text-sm">
        We use this to collect information anonymously about transitions experiences across
        Scotland. Compass works for everyone, whether your young person is attending school or not.
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <RadioGroup
          aria-label="school-type"
          name="school-type"
          value={schoolType}
          onChange={(event) => {
            const schoolType = event.target.value;
            setSchoolType(schoolType);
            setShowOtherSchoolTypeInput(schoolType === 'Other');
          }}>
          {onboardingState.schoolTypes &&
            onboardingState.schoolTypes.map((item) => (
              <FormControlLabel
                key={item}
                label={item}
                value={item}
                control={
                  // 'Other' radio should remain selected when entering custom value in text input field
                  showOtherSchoolTypeInput && item === 'Other' ? (
                    <Radio color="secondary" checked />
                  ) : (
                    <Radio color="secondary" />
                  )
                }
              />
            ))}
        </RadioGroup>
        {showOtherSchoolTypeInput && (
          <input type="text" onChange={(event) => setSchoolType(event.target.value)} autoFocus />
        )}
      </div>
    </>
  );
};

export default SchoolTypePage;
