import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {Route, useParams} from 'react-router-dom';
import {CircularProgress} from '@mui/material';

import {SentryRoutes} from '../Sentry';
import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import {useYoungPerson} from './YoungPersonProvider';
import {useOnboarding} from '../Onboarding/OnboardingProvider';
import EditComponentWithSaveButton from '../Account/EditComponentWithSaveButton';

import AdditionalSupportNeedsPage from '../Onboarding/OnboardingPages/AdditionalSupportNeedsPage';
import SchoolTypePage from './Onboarding/SchoolTypePage';
import LocalAuthorityPage from '../Onboarding/OnboardingPages/LocalAuthorityPage';
import NicknamePage from './Onboarding/NicknamePage';

const EditYoungPersonScreen = () => {
  const {onboardingState} = useOnboarding();
  const {youngPersonID} = useParams();
  const {youngPeople, updateYoungPerson} = useYoungPerson();
  const [youngPersonOriginal, setYoungPersonOriginal] = useState();
  const [youngPersonCopy, setYoungPersonCopy] = useState();
  const [livesInScotland, setLivesInScotland] = useState();

  useEffect(() => {
    // Make a copy of youngPersonOriginal to keep our unsaved changes
    // (check for id to wait for youngPersonOriginal to be loaded)
    if (youngPeople?.length > 0 && youngPersonID) {
      const matchedYoungPerson = youngPeople.find(({id}) => id === parseInt(youngPersonID));
      setYoungPersonOriginal(matchedYoungPerson);
      setYoungPersonCopy({
        ...matchedYoungPerson,
        custom_additional_support_needs: matchedYoungPerson.custom_additional_support_needs.map(
          (need) => need.name
        ),
      });
      setLivesInScotland(!!matchedYoungPerson.home_local_authority);
    }
  }, [youngPeople, youngPersonID]);

  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  return (
    <>
      <PageHeader
        color={dark}
        name={'Edit Young Person'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
        backTo={`/young-person/${youngPersonID}`}
      />
      {youngPersonCopy ? (
        <SentryRoutes>
          <Route
            path="nickname"
            element={
              <EditComponentWithSaveButton
                onClickSave={() =>
                  updateYoungPerson(youngPersonCopy.id, {
                    nickname: youngPersonCopy.nickname,
                  })
                }>
                <NicknamePage
                  nickname={youngPersonCopy.nickname}
                  setNickname={(nickname) => {
                    setYoungPersonCopy({...youngPersonCopy, nickname});
                  }}
                />
              </EditComponentWithSaveButton>
            }
          />
          <Route
            path="additional-needs"
            element={
              <EditComponentWithSaveButton
                buttonDisabled={
                  youngPersonCopy.additional_support_needs.length === 0 &&
                  youngPersonCopy.custom_additional_support_needs.length === 0
                }
                onClickSave={() =>
                  updateYoungPerson(youngPersonID, {
                    additional_support_needs: youngPersonCopy.additional_support_needs,
                    custom_additional_support_needs:
                      youngPersonCopy.custom_additional_support_needs,
                  })
                }>
                {onboardingState.additionalSupportNeeds && (
                  <AdditionalSupportNeedsPage
                    heading="What additional support needs does your young person have?"
                    caption="Please select all that apply."
                    additionalSupportNeeds={onboardingState.additionalSupportNeeds}
                    selectedAdditionalSupportNeeds={youngPersonCopy.additional_support_needs}
                    toggleAdditionalSupportNeed={(need, isChecked) => {
                      if (isChecked) {
                        setYoungPersonCopy({
                          ...youngPersonCopy,
                          additional_support_needs: [
                            ...youngPersonCopy.additional_support_needs,
                            need,
                          ],
                        });
                      } else {
                        const newAdditionalSupportNeeds =
                          youngPersonCopy.additional_support_needs.filter(
                            (item) => item.id !== need.id
                          );
                        setYoungPersonCopy({
                          ...youngPersonCopy,
                          additional_support_needs: newAdditionalSupportNeeds,
                        });
                      }
                    }}
                    customAdditionalSupportNeeds={youngPersonCopy.custom_additional_support_needs}
                    setCustomAdditionalSupportNeeds={(newCustomAdditionalSupportNeeds) =>
                      setYoungPersonCopy({
                        ...youngPersonCopy,
                        custom_additional_support_needs: newCustomAdditionalSupportNeeds,
                      })
                    }
                    otherLabel="Other"
                  />
                )}
              </EditComponentWithSaveButton>
            }
          />
          <Route
            path="school-type"
            element={
              <EditComponentWithSaveButton
                buttonDisabled={youngPersonCopy.schoolType === youngPersonOriginal.schoolType}
                onClickSave={() =>
                  updateYoungPerson(youngPersonID, {
                    school_type: youngPersonCopy.schoolType,
                  })
                }>
                <SchoolTypePage
                  schoolType={youngPersonCopy.schoolType}
                  setSchoolType={(schoolType) =>
                    setYoungPersonCopy({...youngPersonCopy, schoolType})
                  }
                />
              </EditComponentWithSaveButton>
            }
          />
          <Route
            path="residential-local-authority"
            element={
              <EditComponentWithSaveButton
                buttonDisabled={
                  youngPersonCopy.home_local_authority === youngPersonOriginal.home_local_authority
                }
                onClickSave={() =>
                  updateYoungPerson(youngPersonID, {
                    local_authority:
                      onboardingState.localAuthorities.find(
                        (auth) => auth.name === youngPersonCopy.home_local_authority
                      )?.id ?? null,
                  })
                }>
                <LocalAuthorityPage
                  livesInScotlandQuestion={`${
                    youngPersonCopy.has_left_school &&
                    youngPersonCopy.school_type !== 'Home educated'
                      ? 'Did'
                      : 'Does'
                  } your young person live ${
                    youngPersonCopy.school_type === 'Home educated' ? '' : 'and go to school'
                  } in Scotland?`}
                  livesInScotlandCopy={
                    'You can still use Compass, but some of the information we provide might not apply in your area.'
                  }
                  livesInScotland={livesInScotland}
                  setLivesInScotland={setLivesInScotland}
                  title="What local authority does your young person live in?"
                  subtitle="We use this to collect information anonymously about transitions experiences across Scotland."
                  localAuthority={youngPersonCopy.home_local_authority?.name}
                  setLocalAuthority={(home_local_authority) => {
                    setYoungPersonCopy({...youngPersonCopy, home_local_authority});
                  }}
                />
              </EditComponentWithSaveButton>
            }
          />
          <Route
            path="educational-local-authority"
            element={
              <EditComponentWithSaveButton
                buttonDisabled={
                  youngPersonCopy.schoolLocalAuthority === youngPersonOriginal.schoolLocalAuthority
                }
                onClickSave={() =>
                  updateYoungPerson(youngPersonID, {
                    school_local_authority:
                      onboardingState.localAuthorities.find(
                        (auth) => auth.name === youngPersonCopy.schoolLocalAuthority
                      )?.id ?? null,
                  })
                }>
                <LocalAuthorityPage
                  livesInScotland
                  title={`What local authority ${
                    youngPersonCopy.hasLeftSchool ? `did` : `does`
                  } your young person go to school in?`}
                  subtitle="We use this to collect information anonymously about transitions experiences across Scotland."
                  localAuthority={youngPersonCopy.school_local_authority?.name}
                  setLocalAuthority={(schoolLocalAuthority) => {
                    if (schoolLocalAuthority) {
                      setYoungPersonCopy({...youngPersonCopy, schoolLocalAuthority});
                    }
                  }}
                />
              </EditComponentWithSaveButton>
            }
          />
        </SentryRoutes>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default EditYoungPersonScreen;
