import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';
import {Container, Typography, Link} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import PrivacyHeaderIcon from '../img/privacy-header.svg';
import {CONTACT_EMAIL_ADDRESS} from '../Util/constants';

const PrivacyInfoYoungPeople = () => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  return (
    <>
      <PageHeader
        color={dark}
        name={'Privacy Information for Young People'}
        icon={PrivacyHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <div className="home-section">
          <Typography variant="h2">ARC Scotland and Compass</Typography>
          <Typography paragraph>
            We are asking your parents or guardian to give us information about you to help us
            improve the experience of young people with additional support needs to transition to
            becoming young adults.
          </Typography>
          <Typography paragraph>
            To do this we ask your parents or guardians a few questions and some of these are about
            you. We ask them to tell us:
          </Typography>
          <ul>
            <li>which area you live in</li>
            <li>which area you go to school in</li>
            <li>what your additional support needs are</li>
          </ul>

          <Typography paragraph>
            To improve services we will share information with relevant local authorities but the
            information we share will not be linked to you. No one will be able to identify from the
            information we share.
          </Typography>
          <Typography paragraph>We will also keep your data secure.</Typography>
          <Typography paragraph>
            If you have any questions about your data, more information can be found {` `}
            <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
              here
            </Link>
            {` `} or you can contact us using the contact details here:
            <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
              {CONTACT_EMAIL_ADDRESS}
            </Link>
            .
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default PrivacyInfoYoungPeople;
