import React, {useEffect, useState} from 'react';
import {Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {useEvaluation} from '../Evaluation/EvaluationProvider';
import EvaluationThemeStepper from '../Evaluation/EvaluationThemeStepper';
import EvaluationQuestion from './EvaluationQuestion';

const EvaluationTheme = ({evaluationTheme}) => {
  const {evaluationState, saveResponse, dispatch} = useEvaluation();
  const navigate = useNavigate();
  const [responses, setResponses] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    let newResponses = {};
    if (evaluationTheme.themeQuestions.length > 0) {
      evaluationTheme.themeQuestions.forEach((question) => {
        newResponses = {
          ...newResponses,
          [question.id]: {
            questionId: question.id,
            rating: 3,
          },
        };
      });
    } else {
      // move on to next theme if there are no questions
      dispatch({type: 'INCREMENT_CURRENT_THEME_INDEX'});
    }
    setResponses(newResponses);
  }, [evaluationState.currentThemeIndex, evaluationTheme.themeQuestions]);

  const handleSave = async () => {
    return await saveResponse(responses);
  };

  return (
    <>
      <EvaluationThemeStepper
        currentThemeIndex={evaluationState.currentThemeIndex}
        themes={evaluationState.questionsByThemeName}
      />
      {evaluationTheme.themeQuestions.length > 0 && Object.keys(responses).length > 0 && (
        <>
          <Typography variant="h3">{evaluationTheme.name}</Typography>
          {evaluationTheme.themeQuestions.map((question) => {
            return (
              <EvaluationQuestion
                question={question}
                response={responses[question.id]}
                setResponse={(newResponse) =>
                  setResponses({
                    ...responses,
                    [question.id]: newResponse,
                  })
                }
                key={question.id}
              />
            );
          })}
          <div className="form-button">
            <Button
              variant="contained"
              color="secondary"
              onClick={async () => {
                await handleSave();
                dispatch({type: 'SET_QUESTIONS', payload: {}});
                // if all themes have been evaluated, navigate to comments page
                if (
                  evaluationState.currentThemeIndex ===
                  evaluationState.questionsByThemeName.length - 1
                ) {
                  navigate('/feedback/comments');
                }
              }}>
              Next
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default EvaluationTheme;
