import React from 'react';
import {Typography} from '@mui/material';

import YourPersonalData from '../YourPersonalData';

const ParentYourPersonalDataScreen = () => (
  <YourPersonalData
    yourDataContents={
      <>
        <Typography paragraph variant="body2">
          Before you sign up, we need to tell you what will happen to the information you give us.
        </Typography>
        <Typography paragraph variant="body2">
          We’ll share some of it with your local authority, and the Scottish Government.
        </Typography>
        <Typography paragraph variant="body2">
          Don’t worry - nothing can be linked to you or your young person. We'll make sure all your
          data is anonymised and combined with data from other users.
        </Typography>
        <Typography paragraph variant="body2">
          To find out more about how we use your personal data, read our privacy notice (link opens
          in a new tab):
        </Typography>
      </>
    }
  />
);

export default ParentYourPersonalDataScreen;
