import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Button, Container, LinearProgress, Typography} from '@mui/material';

import {useYoungPerson} from '../../Parent/YoungPersonProvider';
import {useTransition} from '../TransitionProvider';
import TransitionQuestionYesNo from './TransitionQuestionYesNo';
import UnlockedItemsNotification from './UnlockedItemsNotification';
import PageHeader from '../../Util/PageHeader';
import {sectionIcons} from '../../Util/constants';

const getCurrentCategoryHeading = (
  hasTransitionQuestions,
  transitionState,
  currentQuestionCategoryId
) => {
  if (!hasTransitionQuestions || !transitionState.categories) return;
  return transitionState.categories.find((category) => category.id === currentQuestionCategoryId)
    .name;
};

const getHeaderText = (categoriesWithNewKeyActions, currentCategoryHeading) => {
  if (categoriesWithNewKeyActions[0]) return categoriesWithNewKeyActions[0].name;
  if (currentCategoryHeading) return currentCategoryHeading;
  return 'Transition Questions';
};

const TransitionQuestions = () => {
  const {youngPerson} = useYoungPerson();
  const {transitionState, answerTransitionQuestion} = useTransition();
  const theme = useTheme();

  const [categoriesWithNewKeyActions, setCategoriesWithNewKeyActions] = useState([]);
  const [numUnlockedTransitionQuestions, setNumUnlockedTransitionQuestions] = useState(0);
  const [numberOfTransitionQuestionsRemaining, setnumberOfTransitionQuestionsRemaining] =
    useState(0);
  const [numberOfTransitionQuestionsAnswered, setNumberOfTransitionQuestionsAnswered] = useState(0);
  const [shouldShowIntroScreen, setShouldShowIntroScreen] = useState(true);

  const isLoading = transitionState.transitionQuestionsLoading;
  const hasUnlockedItems =
    (categoriesWithNewKeyActions && categoriesWithNewKeyActions.length > 0) ||
    numUnlockedTransitionQuestions > 0;
  const hasTransitionQuestions =
    transitionState.transitionQuestions && transitionState.transitionQuestions.length > 0;
  const currentQuestion = hasTransitionQuestions
    ? transitionState.transitionQuestions[0]
    : undefined;
  const currentQuestionId = currentQuestion ? currentQuestion.transition_question.id : undefined;
  const currentQuestionCategoryId = currentQuestion
    ? currentQuestion.transition_question.category_id
    : undefined;
  const currentCategoryHeading = currentQuestion
    ? getCurrentCategoryHeading(hasTransitionQuestions, transitionState, currentQuestionCategoryId)
    : '';
  const headerText = getHeaderText(categoriesWithNewKeyActions, currentCategoryHeading);

  useEffect(() => {
    if (transitionState.transitionQuestions) {
      setnumberOfTransitionQuestionsRemaining(transitionState.transitionQuestions.length);
    }
  }, []);

  return (
    <>
      <PageHeader
        color={theme.palette.secondary.pale}
        name={headerText}
        icon={sectionIcons.transitionQuestionsIcon}
        backTo={
          (hasTransitionQuestions || hasUnlockedItems) && !shouldShowIntroScreen ? '/' : undefined
        }
        backText="Stop for now"
      />
      <LinearProgress
        variant="determinate"
        value={(numberOfTransitionQuestionsAnswered / numberOfTransitionQuestionsRemaining) * 100}
      />

      {isLoading ? (
        <div>Loading...</div>
      ) : hasUnlockedItems ? (
        <UnlockedItemsNotification
          categoriesWithNewKeyActions={categoriesWithNewKeyActions}
          numUnlockedTransitionQuestions={numUnlockedTransitionQuestions}
          clearUnlockedItems={() => {
            setCategoriesWithNewKeyActions([]);
            setNumUnlockedTransitionQuestions(0);
          }}
        />
      ) : hasTransitionQuestions ? (
        shouldShowIntroScreen ? (
          <div className="center-content">
            <Container maxWidth="sm">
              <Typography paragraph textAlign="center">
                Your answers create a personal 'to do' list that helps you work out how to support
                your young person. You'll find your list in Transition Topics. It will update as you
                go through the different stages.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShouldShowIntroScreen(false)}
                style={{float: 'right'}}>
                Continue
              </Button>
            </Container>
          </div>
        ) : (
          <TransitionQuestionYesNo
            youngPersonTransitionQuestion={currentQuestion}
            submitButtonText="Next"
            onSubmit={async (questionResponse) => {
              const unlockedItems = await answerTransitionQuestion(
                youngPerson.id,
                currentQuestionId,
                questionResponse
              );
              const {unlockedKeyActions, unlockedTransitionQuestions} = unlockedItems;
              const categoryIdsWithNewKeyActions = unlockedKeyActions.map((keyAction) =>
                transitionState.categories.find((category) => category.id === keyAction.category_id)
              );
              setNumberOfTransitionQuestionsAnswered(numberOfTransitionQuestionsAnswered + 1);
              setCategoriesWithNewKeyActions(categoryIdsWithNewKeyActions);
              setNumUnlockedTransitionQuestions(unlockedTransitionQuestions.length);
              // increment the number of transition questions if more questions become unlocked
              if (unlockedTransitionQuestions.length > 0) {
                const newRemainingQuestions =
                  numberOfTransitionQuestionsRemaining + unlockedTransitionQuestions.length;
                setnumberOfTransitionQuestionsRemaining(newRemainingQuestions);
              }
            }}
          />
        )
      ) : (
        <div className="center-content">
          <Container maxWidth="sm">
            <Typography variant="h3" textAlign={'center'}>
              Thank you for answering!
            </Typography>
            <div className="right-aligned-button">
              <Button variant="contained" color="secondary" component={RouterLink} to="/">
                Home
              </Button>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default TransitionQuestions;
