import React from 'react';
import {useTheme} from '@mui/material/styles';

import EvaluationOtherComments from '../Evaluation/EvaluationOtherComments';
import PageHeader from '../Util/PageHeader';
import feedbackIcon from '../img/EvaluationLight.svg';
import {Typography} from '@mui/material';

const ParentEvaluationOtherComments = () => {
  const theme = useTheme();
  const {feedbackColor} = theme.palette;
  return (
    <EvaluationOtherComments
      pageHeader={
        <PageHeader
          color={feedbackColor}
          name="Any other comments?"
          icon={feedbackIcon}
          backTo="/feedback"
        />
      }
      content={
        <>
          <Typography paragraph>
            Is there anything else you'd like to share about your experience with transitions? Your
            comments won't be shared directly with your Local Authority, but ARC may read these
            comments and choose to share insights with them.
          </Typography>
          <Typography paragraph sx={{fontStyle: 'italic'}}>
            Please do not include names or any other personal information in these comments.
          </Typography>
        </>
      }
    />
  );
};

export default ParentEvaluationOtherComments;
