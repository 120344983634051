import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Container} from '@mui/system';
import {Typography} from '@mui/material';

import feedbackHistoryIcon from '../img/EvaluationHistoryLight.svg';
import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import EvaluationHistory from '../Evaluation/EvaluationHistory';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';
import {useYoungPerson} from './YoungPersonProvider';

const EvaluationHistoryScreen = () => {
  const theme = useTheme();
  const {youngPerson} = useYoungPerson();

  return (
    <EvaluationHistory
      pageHeader={
        <>
          <PageHeader
            color={theme.palette.feedbackColor}
            name={FEEDBACK_STRINGS.parent.feedbackHistoryTitle}
            icon={feedbackHistoryIcon}
            backTo="/feedback"
          />
          <Container maxWidth="md" sx={{pt: 2}}>
            <ParentCurrentYoungPersonSwitcher verb="viewing" />
          </Container>
        </>
      }
      content={
        <>
          <Typography paragraph>
            Compass lets you see your previous questionnaire responses.
          </Typography>
          <Typography paragraph>
            Select the date you completed a questionnaire to see what you shared with Compass at
            that point in time.
          </Typography>
        </>
      }
      filter={(evaluation) => evaluation.young_person_id === youngPerson.id}
    />
  );
};

export default EvaluationHistoryScreen;
