import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Button, Typography} from '@mui/material';
import {Box, Container} from '@mui/system';

import {useAuth} from '../Auth/AuthProvider';
import {useYoungPerson} from './YoungPersonProvider';
import {useTransition} from '../Transition/TransitionProvider';

import StageCompleteDialog from '../Transition/StageCompleteDialog';
import ParentTransitionProgressIndicator from './ParentTransitionProgressIndicator';
import NotificationBadge from './Home/NotificationBadge';
import HomeScreenCard from './Home/HomeScreenCard';
import KeyActionBadge from './KeyActionBadge';
import stageCompleteIcon from '../img/clock.svg';
import completeTickIcon from '../img/complete-tick-secondary.svg';
import {FEEDBACK_STRINGS, longDateFormatter, sectionIcons} from '../Util/constants';
import HomeScreenTour from '../Util/HomeScreenTour';
import HomeFeedbackCard from '../HomeFeedbackCard';
import {useRedirectAfterAuth} from '../Util/StoreRedirectUrlForAuth';

const TOUR_JOYRIDE_STEPS = [
  {
    target: '#home-button',
    disableBeacon: true,
    title: 'Welcome to Compass!',
    content: (
      <>
        <Typography>This is your Home screen. From here you can:</Typography>
        <ul>
          <li>See where you are in the transitions process</li>
          <li>Answer questions to get personalised advice</li>
          <li>Keep track of your actions and access helpful resources</li>
          <li>Give anonymous feedback to your local authority</li>
        </ul>
      </>
    ),
  },
  {
    target: '#glossary-nav-button',
    title: 'Transitions A-Z',
    fixed: true,
    content: (
      <>
        <Typography paragraph>
          The Transitions A-Z lets you look up commonly used terms to help you understand the jargon
          often used during transitions.
        </Typography>
        <Typography paragraph>
          Each item has Helpful Links so you can learn more about topics when you need to.
        </Typography>
      </>
    ),
  },
  {
    target: '#young-person-nav-button',
    title: 'Your young person',
    content: (
      <>
        <Typography paragraph>
          This shows the young person you're currently using Compass for. If you need to view or
          update the details for this young person, you can click this icon.
        </Typography>
      </>
    ),
  },
  {
    target: '#menu',
    title: 'Main menu',
    content: (
      <>
        <Typography paragraph>
          This is the main menu. You can use it to navigate to different parts of Compass and toggle
          between dark/light display mode.
        </Typography>
      </>
    ),
  },
  {
    target: '#stage-details',
    title: 'Transition Stage',
    content: (
      <>
        <Typography paragraph>
          This shows your current stage in the transitions process, and when you'll move to the next
          stage.
        </Typography>
        <Typography paragraph>
          Compass breaks up the transitions process into several stages, to make it easier to
          understand where you are and what to expect. As time passes and you complete actions in
          Compass, you will advance through these stages.
        </Typography>
      </>
    ),
  },
  {
    target: '#what-now-card',
    title: '"What Now?" questions',
    placement: 'right',
    content: (
      <Typography paragraph>
        These questions help Compass understand your situation, so it can give you advice and
        resources based on your needs.
      </Typography>
    ),
  },
  {
    target: '#catching-up-card',
    title: 'Catch up on previous stages',
    placement: 'right',
    content: (
      <Typography paragraph>
        It looks like you've joined Compass part way through the transitions process. Here you find
        important things to do from previous stages to make sure you're ready for the next stage.
      </Typography>
    ),
  },
  {
    target: '#transition-topics-card',
    title: 'Transition Topics',
    placement: 'left',
    content: (
      <>
        <Typography paragraph>
          Here you can find your personal list of tasks, based on the answers you give to "What Now"
          questions.
        </Typography>
        <Typography paragraph>
          Compass has links to resources which can help guide you, and you can mark actions as
          complete when you're ready to move on.
        </Typography>
      </>
    ),
  },
  {
    target: '#feedback-card-content',
    title: 'Giving Feedback',
    placement: 'top',
    content: (
      <>
        <Typography paragraph>
          Compass asks for your thoughts and experiences throughout the transitions process.
        </Typography>
        <Typography paragraph>
          We share this information with your local authority to improve the transitions process.
          Your answers are anonymous and we add them to responses from others in your area so no one
          can identify you or your family.
        </Typography>
      </>
    ),
  },
];

const ParentHome = () => {
  const {authState, updateUser} = useAuth();
  const theme = useTheme();
  const {tellMeMoreColor, transitionColor} = theme.palette;
  const {
    transitionState,
    getKeyActions,
    fetchUnansweredTransitionQuestions,
    fetchCheckStageComplete,
    numIncompleteActions,
  } = useTransition();
  const {youngPerson} = useYoungPerson();
  const navigate = useNavigate();

  // If there's a stored URL when we mount, navigate there
  const {handleAuthenticatedAppBoot} = useRedirectAfterAuth();
  useEffect(handleAuthenticatedAppBoot, []);

  const [shouldShowStageCompleteDialog, setShouldShowStageCompleteDialog] = useState(false);

  const {transitionQuestionsIcon, transitionTopicsIcon, feedbackIcon} = sectionIcons;
  const badgeStyle = {marginRight: 12};

  const {feedbackTitle, feedbackButtonTitle} = FEEDBACK_STRINGS.parent;

  useEffect(() => {
    const init = async () => {
      // Refresh transition state information every time we load this screen
      getKeyActions(youngPerson.id);
      fetchUnansweredTransitionQuestions(youngPerson.id);
      if (await fetchCheckStageComplete(youngPerson.id)) {
        setShouldShowStageCompleteDialog(true);
      }
    };
    if (youngPerson.id) {
      init();
    }
  }, [youngPerson.id]);

  const isLoading = !youngPerson?.id || transitionState.transitionQuestionsLoading;

  const {hasCompletedNodes, hasReachedNextStageStartDate, nextStageStartDate} =
    transitionState.stageCompleteStatus;

  const hasTransitionQuestions =
    transitionState.transitionQuestions && transitionState.transitionQuestions.length > 0;

  const [showTour, setShowTour] = useState(authState.user.should_show_intro_tour);
  const onCompleteTour = async () => {
    await updateUser({should_show_intro_tour: false});
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <HomeScreenTour
        run={showTour}
        setRun={setShowTour}
        steps={TOUR_JOYRIDE_STEPS}
        onComplete={onCompleteTour}
        hideProgress
      />
      <StageCompleteDialog
        isVisible={shouldShowStageCompleteDialog}
        closeDialog={() => navigate('/stage-complete')}
      />
      <Box id="home-banner" sx={{pt: 2}}>
        <Container maxWidth="md">
          <div className="name-row">
            <Typography variant="h3" className="white">
              {`Hello ${authState.user.name}!`}
            </Typography>
            <Button variant="contained" color="secondary" onClick={() => setShowTour(true)}>
              Help
            </Button>
          </div>
          {youngPerson && <ParentTransitionProgressIndicator />}
        </Container>
      </Box>

      <Container maxWidth="md">
        <div className="home-card-buttons-container">
          {hasCompletedNodes && !hasReachedNextStageStartDate ? (
            <HomeScreenCard
              id="what-now-card"
              disabled={true}
              headerBackgroundColor={tellMeMoreColor}
              headerIconSrc={stageCompleteIcon}
              title="You have completed this stage!"
              description={
                <div>
                  <div className="body2">
                    You have answered all the questions and completed all the things to do to
                    support your young person at this stage of their transition to young adult life.
                  </div>
                  {nextStageStartDate && (
                    <p className="body2 semibold">
                      You will unlock new questions to answer on{' '}
                      {longDateFormatter.format(nextStageStartDate)}
                    </p>
                  )}
                </div>
              }
            />
          ) : hasTransitionQuestions ? (
            <HomeScreenCard
              id="what-now-card"
              onClick={() => navigate('/transition-questions')}
              headerBackgroundColor={tellMeMoreColor}
              headerIconSrc={transitionQuestionsIcon}
              title="What Now?"
              description="You have some questions to answer. They will help identify things it may be useful to know about to support your young person in their transition to young adult life."
              actionRow={
                <>
                  <NotificationBadge
                    data-testid="transition-questions-badge"
                    value={transitionState.transitionQuestions?.length}
                    style={badgeStyle}
                  />
                  <div className="subtitle2">New Questions</div>
                </>
              }
            />
          ) : (
            <HomeScreenCard
              id="what-now-card"
              disabled={true}
              headerBackgroundColor={tellMeMoreColor}
              headerIconSrc={completeTickIcon}
              title="What Now?"
              description="You have answered the questions that identify the most important things just now. When you complete 'Things to Do' in Transition Topics, you will be asked more questions to identify other things that might be useful for you."
            />
          )}
          <HomeScreenCard
            id="transition-topics-card"
            onClick={() => navigate('/transition-topics')}
            headerBackgroundColor={transitionColor}
            headerIconSrc={transitionTopicsIcon}
            title="Transition Topics"
            description={
              numIncompleteActions > 0
                ? 'These are things to look into as you go through the transitions journey. Your tasks within each topic are based on the questions you answered.'
                : 'These are things to look into as you go through the transitions journey. Your tasks within each topic will be based on the questions you answer. You currently have no tasks.'
            }
            actionRow={
              numIncompleteActions > 0 ? (
                <>
                  <KeyActionBadge style={badgeStyle} />
                  <div className="subtitle2">
                    {numIncompleteActions} Thing{numIncompleteActions > 1 && 's'} to Do
                  </div>
                </>
              ) : (
                <div className="subtitle2">Browse Topics</div>
              )
            }
          />
        </div>

        <HomeFeedbackCard
          iconSrc={feedbackIcon}
          feedbackTitle={feedbackTitle}
          description="By telling us about your transitions experience, we can improve the process for you, as well as other parents and carers in your area."
          feedbackButtonTitle={feedbackButtonTitle}
        />
      </Container>
    </>
  );
};

export default ParentHome;
