import React from 'react';

const IsSchoolLASameAsHomeLAPage = ({
  hasLeftSchool,
  schoolType,
  isSchoolLASameAsHomeLA,
  setIsSchoolLASameAsHomeLA,
}) => (
  <>
    <h1 className="header-4 auth-text">
      {`${hasLeftSchool ? `Did` : `Does`} your young person go to school in this local authority${
        schoolType === 'Home educated' || schoolType === 'Other'
          ? ', or are they home educated here'
          : ''
      }?`}
    </h1>
    <div className="body2 auth-text-sm">
      We use this to collect information anonymously about transitions experiences across Scotland.
    </div>
    <div className="yes-no-buttons">
      <button
        className={`question-button dark ${isSchoolLASameAsHomeLA ? 'selected' : ''}`}
        type="button"
        onClick={() => setIsSchoolLASameAsHomeLA(true)}
        autoFocus>
        Yes
      </button>
      <button
        className={`question-button dark ${isSchoolLASameAsHomeLA === false ? 'selected' : ''}`}
        type="button"
        onClick={() => setIsSchoolLASameAsHomeLA(false)}>
        No
      </button>
    </div>
  </>
);

export default IsSchoolLASameAsHomeLAPage;
