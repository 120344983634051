import React from 'react';

const NotificationBadge = ({'data-testid': dataTestId, value, style}) => {
  return (
    <div
      data-testid={dataTestId}
      className="badge notification-badge"
      style={{
        ...style,
      }}>
      {value}
    </div>
  );
};

export default NotificationBadge;
