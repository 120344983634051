import React, {useState, useEffect} from 'react';
import {Button, Container} from '@mui/material';

const TransitionQuestionYesNo = ({youngPersonTransitionQuestion, submitButtonText, onSubmit}) => {
  const [questionResponse, setQuestionResponse] = useState(undefined);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);

  const {transition_question: transitionQuestion} = youngPersonTransitionQuestion;
  const {title} = transitionQuestion;

  useEffect(() => {
    setQuestionResponse(undefined);
    setNextButtonClicked(false);
  }, [youngPersonTransitionQuestion]);

  return (
    <div className="center-content">
      <Container maxWidth="sm">
        <div className="header-4 centre-aligned">{title}</div>
        <div className="yes-no-buttons">
          <button
            className={`question-button ${questionResponse === true ? 'selected' : ''}`}
            type="button"
            onClick={() => setQuestionResponse(true)}>
            Yes
          </button>
          <button
            className={`question-button ${questionResponse === false ? 'selected' : ''}`}
            type="button"
            onClick={() => setQuestionResponse(false)}>
            No / I'm not sure
          </button>
        </div>
        <div className="right-aligned-button">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setNextButtonClicked(true);
              onSubmit(questionResponse);
            }}
            disabled={questionResponse === undefined || nextButtonClicked}>
            {submitButtonText}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default TransitionQuestionYesNo;
