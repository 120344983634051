import React from 'react';
import {CircularProgress, Container} from '@mui/material';

import {useYoungPerson} from '../Parent/YoungPersonProvider';
import PredictedTimelinePage from '../Parent/Onboarding/PredictedTimelinePage';
import BackLink from '../Util/BackLink';

const FullTimeline = () => {
  const {youngPerson} = useYoungPerson();
  return youngPerson ? (
    <Container maxWidth="md" sx={{py: 6}}>
      <BackLink to="/" />
      <PredictedTimelinePage
        expectedLeavingDate={youngPerson.expectedLeavingDate}
        currentStage={youngPerson.stage}
        showGlossaryLinks={true}
      />
    </Container>
  ) : (
    <CircularProgress sx={{alignSelf: 'center'}} />
  );
};

export default FullTimeline;
