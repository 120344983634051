import React from 'react';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {Button, Typography} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box} from '@mui/system';

import {useYoungPerson} from './YoungPersonProvider';
import {useTransition} from '../Transition/TransitionProvider';
import {useOnboarding} from '../Onboarding/OnboardingProvider';

import LinearProgressIndicator from '../Util/LinearProgressIndicator';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';
import {formatDateAsTerm} from '../Util/helpers';
import welcomePerson from '../img/welcome-man.svg';
import marker from '../img/parent-triangle.svg';
import {useDarkModeTheme} from '../DarkModeThemeProvider';

const ParentTransitionProgressIndicator = () => {
  const navigate = useNavigate();
  const {youngPerson} = useYoungPerson();
  const {transitionState, stageDates} = useTransition();
  const {onboardingState} = useOnboarding();
  const theme = useTheme();

  const {stageCompleteStatus} = transitionState;

  const currentStage = youngPerson.id ? youngPerson.stage : undefined;
  const {stages} = onboardingState;
  const currentStageStartDate = new Date(
    stageDates?.find((stage) => stage.id === currentStage.id)?.stage_start_date
  );
  const nextStageStartDate = stageCompleteStatus?.nextStageStartDate;
  const currentStageStartDateString = currentStageStartDate
    ? formatDateAsTerm(currentStageStartDate)
    : '';
  const nextStageStartDateString = stageCompleteStatus.nextStageStartDate
    ? formatDateAsTerm(nextStageStartDate)
    : '';

  const stageDurationInMilliseconds = nextStageStartDate - currentStageStartDate;
  const millisecondsSinceStageStart = new Date() - currentStageStartDate;
  const percentageProgress = (millisecondsSinceStageStart / stageDurationInMilliseconds) * 100;
  const {isDarkMode} = useDarkModeTheme();

  return (
    <Box
      sx={{
        padding: 1,
        [theme.breakpoints.up('sm')]: {padding: 2},
        [theme.breakpoints.up('md')]: {padding: 3},
        backgroundColor: theme.palette.background.default,
        minHeight: 250,
        mb: 2,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
      }}>
      <Box sx={{flex: 1}} id="stage-details">
        <ParentCurrentYoungPersonSwitcher verb="viewing" noun="transition information" />
        {currentStage && stages && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}>
            <Box>
              <Typography variant="h5" className={isDarkMode ? undefined : 'primary-dark'}>
                {`Stage ${currentStage.index + 1}: ${currentStage.name}`}
              </Typography>
              {!transitionState.stageCompleteStatus.nextStageStartDate && (
                <div className="body2">This is the final stage of your transitions journey.</div>
              )}
            </Box>
            <Button
              sx={{alignSelf: 'flex-end'}}
              variant="outlined"
              onClick={() => navigate('/transition-timeline')}
              size="small">
              See full timeline
              <ArrowForwardIcon color="secondary" sx={{ml: 1}} />
            </Button>
          </Box>
        )}

        {transitionState.stageCompleteStatus.nextStageStartDate && (
          <Box sx={{mt: 1}}>
            <LinearProgressIndicator
              percentageProgress={percentageProgress}
              outerColor={theme.palette.primary.pale}
              innerColor={theme.palette.primary.dark}
            />
            <div className="progress-indicator-markers-row">
              <div className="progress-indicator-marker-container left-aligned">
                <img alt="" className="progress-indicator-marker" src={marker} height={5} />
                <div className="caption">{currentStageStartDateString}</div>
              </div>
              <div className="progress-indicator-marker-container right-aligned">
                <img alt="" className="progress-indicator-marker" src={marker} height={5} />
                <div className="caption">Next Stage: {nextStageStartDateString}</div>
              </div>
            </div>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'none',
          width: 123,
          [theme.breakpoints.up('sm')]: {
            display: 'block',
          },
        }}>
        <img src={welcomePerson} alt="" style={{position: 'absolute', top: -35, right: 0}} />
      </Box>
    </Box>
  );
};
export default ParentTransitionProgressIndicator;
