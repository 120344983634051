import React from 'react';
import {Dialog, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';

const AgeWarningDialog = ({isVisible, text, closeDialog}) => {
  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>{text.title}</DialogTitle>
      <DialogContent>
        <Typography paragraph>{text.message}</Typography>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={closeDialog}>
            Okay
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AgeWarningDialog;
