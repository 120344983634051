import React, {useEffect} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Container, Typography} from '@mui/material';

import {useYoungPerson} from '../Parent/YoungPersonProvider';
import {useTransition} from './TransitionProvider';
import CategoryTile from './Categories/CategoryTile';
import PageHeader from '../Util/PageHeader';
import {
  sectionIcons,
  TRANSITION_TOPICS_SCREEN_DESCRIPTION_IMPORTANT_CATEGORY_KEYS,
} from '../Util/constants';

const TransitionTopicsScreen = () => {
  const {youngPerson} = useYoungPerson();
  const {transitionState, getKeyActions} = useTransition();
  const theme = useTheme();
  const {transitionColor} = theme.palette;

  useEffect(() => {
    const init = async () => {
      // Refresh transition state information every time we load this screen
      getKeyActions(youngPerson.id);
      // TODO - setShouldShowStageCompleteDialog doesn't exist in this component...
      // if (await fetchCheckStageComplete(youngPerson.id)) {
      // setShouldShowStageCompleteDialog(true);
      // }
    };
    if (youngPerson.id) {
      init();
    }
  }, [youngPerson.id]);

  const importantCategories = TRANSITION_TOPICS_SCREEN_DESCRIPTION_IMPORTANT_CATEGORY_KEYS.map(
    (categoryKey) =>
      transitionState.categories?.find((category) => {
        return category.key === categoryKey ?? false;
      })
  );
  const importantCategoriesString = importantCategories
    .map((category) => category?.name ?? '')
    .join(' and ');

  return (
    <>
      <PageHeader
        color={transitionColor}
        name={'Transition Topics'}
        icon={sectionIcons.transitionTopicsIcon}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        {transitionState.categoriesLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Typography paragraph>
              These are things to do at your stage in the transitions journey, based on your answers
              to the 'What Now?' questions.
            </Typography>
            <Typography paragraph>
              You can explore them in any order you like, but to help you get started the most
              important topics for most people are {importantCategoriesString}. You can come back to
              the topics at any time.
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gap: {xs: 2, md: 4},
                gridAutoFlow: 'row',
                gridTemplateColumns: {sm: '1fr 1fr'},
              }}>
              {transitionState.categories &&
                transitionState.categories.map((category) => (
                  <CategoryTile key={`category-${category.id}`} category={category} />
                ))}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default TransitionTopicsScreen;
