import React from 'react';
import {Typography} from '@mui/material';
import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';

import Register from '../Auth/Register';
import {RegisterIcon} from '../Auth/RegisterIcon';
import PasswordIcon from '../img/password.svg';
import EmailIcon from '../img/email.svg';

const RegisterScreen = () => (
  <Register
    title="Let's get started!"
    nameHeader={
      <>
        <RegisterIcon>
          <FaceTwoToneIcon sx={{height: 80, width: 80}} color="primary" />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          What's your name?
        </Typography>
      </>
    }
    emailHeader={
      <>
        <RegisterIcon>
          <img src={EmailIcon} height={60} width={60} />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          What's your email address?
        </Typography>
        <Typography paragraph>We use this to make an account</Typography>
      </>
    }
    passwordHeader={
      <>
        <RegisterIcon>
          <img src={PasswordIcon} height={60} width={60} />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          Create a password
        </Typography>
      </>
    }
    showPhoneField={false}
  />
);

export default RegisterScreen;
