import React, {createContext, useContext, useReducer, useEffect, useState} from 'react';

import {useAuth} from '../Auth/AuthProvider';
import {useOnboarding} from '../Onboarding/OnboardingProvider';
import {useYoungPerson} from '../Parent/YoungPersonProvider';

export const TransitionContext = createContext();

const KEY_ACTIONS_URL = '/api/key-actions';
const CATEGORIES_URL = '/api/categories';
const TRANSITION_QUESTIONS_URL = 'api/transition-questions';
const YOUNG_PERSON_URL = 'api/young-person';

const initialState = {
  keyActionsLoading: true,
  categoriesLoading: true,
  transitionQuestionsLoading: true,
  keyActions: undefined,
  categories: undefined,
  transitionQuestions: undefined,
  newKeyActions: {},
  youngPerson: {
    id: undefined,
    dob: {
      month: '',
      year: '',
    },
    additionalSupportNeeds: [],
    customAdditionalSupportNeeds: [],
    hasLeftSchool: undefined,
    schoolType: '',
    schoolYear: '',
    homeLocalAuthority: undefined,
    schoolLocalAuthority: undefined,
    hasExpectedLeavingDate: undefined,
    expectedLeavingDate: {
      term: '',
      year: '',
    },
    stage: undefined,
  },
  stageCompleteStatus: {
    hasCompletedNodes: undefined,
    hasReachedNextStageStartDate: undefined,
    nextStageStartDate: undefined,
  },
};

const transitionReducer = (transitionState, action) => {
  switch (action.type) {
    case 'SET_KEY_ACTIONS':
      return Object.assign({}, transitionState, {
        keyActions: action.payload,
        keyActionsLoading: false,
      });
    case 'SET_COMPLETED_FOR_KEY_ACTION': {
      // Create a deep copy of the categoryKeyActions
      // to avoid directly modifying state
      const newCategoryKeyActions = JSON.parse(
        JSON.stringify(transitionState.keyActions[action.payload.categoryId])
      );

      // Find the action we need to update
      const matchingKeyAction = newCategoryKeyActions.find(
        (keyAction) => keyAction.key_action_id === action.payload.keyActionId
      );
      // Update it
      matchingKeyAction.completed = action.payload.completed;

      return Object.assign({}, transitionState, {
        keyActions: {
          ...transitionState.keyActions,
          [action.payload.categoryId]: newCategoryKeyActions,
        },
      });
    }
    case 'SET_CATEGORIES': {
      return Object.assign({}, transitionState, {
        categories: action.payload,
        categoriesLoading: false,
      });
    }
    case 'SET_TRANSITION_QUESTIONS': {
      return Object.assign({}, transitionState, {
        transitionQuestions: action.payload,
        transitionQuestionsLoading: false,
      });
    }
    case 'REMOVE_TRANSITION_QUESTION_WITH_ID': {
      const transitionQuestionsCopy = JSON.parse(
        JSON.stringify(transitionState.transitionQuestions)
      );
      const remainingTransitionQuestions = transitionQuestionsCopy.filter(
        (youngPersonTransitionQuestion) =>
          youngPersonTransitionQuestion.transition_question.id !== action.payload
      );
      return Object.assign({}, transitionState, {
        transitionQuestions: remainingTransitionQuestions,
      });
    }
    case 'SET_NEW_TRANSITION_ITEMS': {
      const {unlockedKeyActions, unlockedTransitionQuestions} = action.payload;
      // key actions
      const keyActionsDeepCopy = JSON.parse(JSON.stringify(transitionState.newKeyActions));
      Object.values(unlockedKeyActions).forEach((unlockedKeyAction) => {
        const categoryId = unlockedKeyAction.category_id;
        // make sure the key exists (category ID) before attempting to push to it
        if (!(categoryId in keyActionsDeepCopy)) {
          keyActionsDeepCopy[categoryId] = [];
        }
        keyActionsDeepCopy[categoryId].push(unlockedKeyAction);
      });

      // transition questions
      const transitionQuestionsDeepCopy = JSON.parse(
        JSON.stringify(transitionState.transitionQuestions)
      );
      Object.values(unlockedTransitionQuestions).forEach((unlockedTransitionQuestion) => {
        transitionQuestionsDeepCopy.unshift(unlockedTransitionQuestion);
      });

      return Object.assign({}, transitionState, {
        newKeyActions: keyActionsDeepCopy,
        transitionQuestions: transitionQuestionsDeepCopy,
      });
    }
    case 'SET_YOUNG_PERSON_DATA': {
      return Object.assign({}, transitionState, {
        youngPerson: action.payload,
      });
    }
    case 'SET_STAGE_COMPLETE_STATE': {
      return Object.assign({}, transitionState, {
        stageCompleteStatus: action.payload,
      });
    }
  }
};

function TransitionProvider({children}) {
  const {axios} = useAuth();
  const [transitionState, transitionDispatch] = useReducer(transitionReducer, initialState);
  const {calculateTransitionStageDates, calculateCurrentTransitionStage} = useOnboarding();
  const [stageDates, setStageDates] = useState(undefined);
  const [nextStage, setNextStage] = useState(undefined);
  const {youngPerson, fetchYoungPerson} = useYoungPerson();

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const init = async () => {
      fetchUnansweredTransitionQuestions(youngPerson.id);
      fetchCheckStageComplete(youngPerson.id);
    };
    if (youngPerson?.id) {
      init();
    }
  }, [youngPerson?.id]);

  useEffect(() => {
    if (youngPerson?.expectedLeavingDate) {
      const {expectedLeavingDate} = youngPerson;
      if (expectedLeavingDate && expectedLeavingDate.term && expectedLeavingDate.year) {
        calculateTransitionStageDates(expectedLeavingDate.term, expectedLeavingDate.year).then(
          (transitionStageDates) => setStageDates(transitionStageDates)
        );
        calculateCurrentTransitionStage(expectedLeavingDate.term, expectedLeavingDate.year).then(
          (currentTransitionStage) => setNextStage(currentTransitionStage)
        );
      }
    }
  }, [youngPerson?.expectedLeavingDate]);

  const getKeyActions = async (youngPersonId) => {
    const keyActionsResponse = await axios.get(`${KEY_ACTIONS_URL}/${youngPersonId}`);
    const {data} = keyActionsResponse;

    let newKeyActionsKeyedByCateogoryId = {};
    data.forEach((youngPersonKeyAction) => {
      let category_id = youngPersonKeyAction.key_action.category_id;
      if (!(category_id in newKeyActionsKeyedByCateogoryId)) {
        newKeyActionsKeyedByCateogoryId[category_id] = [];
      }
      newKeyActionsKeyedByCateogoryId[category_id].push(youngPersonKeyAction);
    });

    transitionDispatch({type: 'SET_KEY_ACTIONS', payload: newKeyActionsKeyedByCateogoryId});
  };

  const fetchCategories = async () => {
    const categoriesResponse = await axios.get(CATEGORIES_URL);
    transitionDispatch({type: 'SET_CATEGORIES', payload: categoriesResponse.data});
  };

  const fetchUnansweredTransitionQuestions = async (youngPersonId) => {
    const unansweredTransitionQuestionsResponse = await axios.get(
      `${TRANSITION_QUESTIONS_URL}/${youngPersonId}`
    );
    transitionDispatch({
      type: 'SET_TRANSITION_QUESTIONS',
      payload: unansweredTransitionQuestionsResponse.data,
    });
  };

  const removeTransitionQuestionWithId = (transitionQuestionId) => {
    transitionDispatch({
      type: 'REMOVE_TRANSITION_QUESTION_WITH_ID',
      payload: transitionQuestionId,
    });
  };

  const handleUnlockedItems = (unlockedItems) => {
    transitionDispatch({
      type: 'SET_NEW_TRANSITION_ITEMS',
      payload: unlockedItems,
    });
  };

  const answerTransitionQuestion = async (youngPersonId, questionId, response) => {
    try {
      let answerTransitionQuestionResponse = await axios.put(
        `${TRANSITION_QUESTIONS_URL}/${youngPersonId}`,
        {
          questionId,
          response,
        }
      );
      const unlockedItems = answerTransitionQuestionResponse.data;

      handleUnlockedItems(unlockedItems);
      removeTransitionQuestionWithId(questionId);
      // todo this needs to return all unlocked stuff, the destructuring of categories with new key actions etc needs to happen elsewhere
      return unlockedItems;
    } catch {
      alert('There was a problem saving your response, please refresh the page and try again.');
    }
  };

  const updateKeyActionCompletedStatus = async (youngPersonId, youngPersonKeyAction, completed) => {
    const {key_action: keyAction, id: youngPersonKeyActionId} = youngPersonKeyAction;
    const {category_id: categoryId, id: keyActionId} = keyAction;

    const youngPersonKeyActionCompletedStatusResponse = await axios.put(
      `${KEY_ACTIONS_URL}/${youngPersonId}/complete`,
      {
        youngPersonKeyActionId,
        completed,
      }
    );
    const unlockedItems = youngPersonKeyActionCompletedStatusResponse.data;
    handleUnlockedItems(unlockedItems);

    transitionDispatch({
      type: 'SET_COMPLETED_FOR_KEY_ACTION',
      payload: {
        categoryId,
        keyActionId,
        completed,
      },
    });

    return unlockedItems;
  };

  const allYoungPersonKeyActions = transitionState.keyActions
    ? Object.values(transitionState.keyActions).flat()
    : [];
  const numIncompleteActions = allYoungPersonKeyActions.filter(
    (action) => !action.completed
  ).length;

  const fetchCheckStageComplete = async (youngPersonId) => {
    const stageCompleteResponse = await axios.get(
      `${YOUNG_PERSON_URL}/${youngPersonId}/check-stage-complete`
    );
    let {
      has_completed_nodes: hasCompletedNodes,
      has_reached_next_stage_start_date: hasReachedNextStageStartDate,
      next_stage_start_date: nextStageStartDate,
    } = stageCompleteResponse.data;
    transitionDispatch({
      type: 'SET_STAGE_COMPLETE_STATE',
      payload: {
        hasCompletedNodes,
        hasReachedNextStageStartDate,
        nextStageStartDate: nextStageStartDate ? new Date(nextStageStartDate) : null,
      },
    });
    return hasCompletedNodes && hasReachedNextStageStartDate;
  };

  const putStageTransition = async (youngPersonId) => {
    const stageTransitionResponse = await axios.put(
      `${YOUNG_PERSON_URL}/${youngPersonId}/stage-transition`
    );
    let {data} = stageTransitionResponse;
    if (data === true) {
      // If we transitioned successfully, re-fetch the young person & their stage
      fetchYoungPerson(youngPersonId);
    }
    return data === true;
  };

  const value = {
    transitionState,
    transitionDispatch,
    getKeyActions,
    fetchUnansweredTransitionQuestions,
    answerTransitionQuestion,
    updateKeyActionCompletedStatus,
    numIncompleteActions,
    fetchCheckStageComplete,
    putStageTransition,
    stageDates,
    nextStage,
  };
  return youngPerson ? (
    <TransitionContext.Provider value={value}>{children}</TransitionContext.Provider>
  ) : (
    <div>Loading</div>
  );
}

const useTransition = () => useContext(TransitionContext);

export {TransitionProvider, useTransition, transitionReducer};
