import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Container} from '@mui/system';
import {Link, Typography} from '@mui/material';

import CompletedEvaluation from '../Evaluation/CompletedEvaluation';
import PageHeader from '../Util/PageHeader';
import feedbackIcon from '../img/EvaluationLight.svg';
import {CONTACT_EMAIL_ADDRESS, FEEDBACK_STRINGS} from '../Util/constants';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';

const ParentCompletedEvaluationScreen = () => {
  const theme = useTheme();
  const {feedbackColor} = theme.palette;

  return (
    <CompletedEvaluation
      pageHeader={
        <>
          <PageHeader
            color={feedbackColor}
            name={FEEDBACK_STRINGS.parent.feedbackTitle}
            icon={feedbackIcon}
            backTo="/feedback"
          />
          <Container maxWidth="md" sx={{pt: 2}}>
            <ParentCurrentYoungPersonSwitcher verb="leaving" />
          </Container>
        </>
      }
      content={
        <>
          <Typography variant="h2" gutterBottom>
            Thank you for answering these questions.
          </Typography>
          <Typography paragraph>
            Your answers will help improve the transitions process for you and others in your area,
            and across Scotland.
          </Typography>
          <Typography paragraph>
            If you have more feedback or you've had technical problems with Compass, please email us
            at{' '}
            <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
              {CONTACT_EMAIL_ADDRESS}
            </Link>
            .
          </Typography>
          <Typography paragraph>
            You can see your previous responses under '
            {FEEDBACK_STRINGS.parent.feedbackHistoryTitle}'.
          </Typography>
        </>
      }
    />
  );
};

export default ParentCompletedEvaluationScreen;
