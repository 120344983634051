import React, {useEffect, useState} from 'react';
import {Container} from '@mui/system';
import {useTheme} from '@mui/material/styles';
import {Box, Button, LinearProgress, Typography} from '@mui/material';

import {useYoungPerson} from '../YoungPersonProvider';
import {useOnboarding} from '../../Onboarding/OnboardingProvider';
import AgeWarningDialog from '../../Onboarding/AgeWarningDialog';
import TrialUnavailableInLADialog from '../../Onboarding/TrialUnavailableInLADialog';
import {
  MIN_YEAR_OF_BIRTH_PARENT_TOOL,
  MAX_YEAR_OF_BIRTH_PARENT_TOOL,
  RESTRICT_SIGNUP_LOCAL_AUTHORITIES,
  TRIAL_LOCAL_AUTHORITIES,
} from '../../Util/constants';
import {calculateAge} from '../../Util/helpers';
import DateInputPage from '../../Onboarding/OnboardingPages/DateInputPage';
import AdditionalSupportNeedsPage from '../../Onboarding/OnboardingPages/AdditionalSupportNeedsPage';
import HasLeftSchoolPage from './HasLeftSchoolPage';
import SchoolTypePage from './SchoolTypePage';
import SchoolYearPage from './SchoolYearPage';
import LocalAuthorityPage from '../../Onboarding/OnboardingPages/LocalAuthorityPage';
import IsSchoolLASameAsHomeLAPage from './IsSchoolLASameAsHomeLAPage';
import HasExpectedLeavingDatePage from './HasExpectedLeavingDatePage';
import ExpectedLeavingDatePage from './ExpectedLeavingDatePage';
import PredictedTimelinePage from './PredictedTimelinePage';
import PageHeader from '../../Util/PageHeader';
import NicknamePage from './NicknamePage';
import useSubmitParentOnboarding from '../../Queries/useSubmitParentOnboarding';
import {useAuth} from '../../Auth/AuthProvider';

const Onboarding = () => {
  const {fetchUser} = useAuth();
  const theme = useTheme();
  const {transitionColor} = theme.palette;

  const {onboardingState, calculatePotentialExpectedLeavingDates} = useOnboarding();
  const {isLoading: isSubmitting, mutate: submitParentOnboardingData} = useSubmitParentOnboarding();
  const {
    isAddingAdditionalYoungPerson,
    setIsAddingAdditionalYoungPerson,
    setSelectedYoungPersonID,
  } = useYoungPerson();

  const [currentStage, setCurrentStage] = useState(undefined);
  const [showAgeWarningDialog, setShowAgeWarningDialog] = useState(false);
  const [dialogText, setDialogText] = useState({
    title: '',
    message: '',
  });
  const [showTrialUnavailableDialog, setShowTrialUnavailableDialog] = useState(false);

  const [formData, setFormData] = useState({
    nickname: '',
    dob: {
      month: '',
      year: '',
    },
    additionalSupportNeeds: [],
    customAdditionalSupportNeeds: [],
    hasLeftSchool: undefined,
    schoolType: '',
    schoolYear: '',
    homeLocalAuthority: undefined,
    schoolLocalAuthority: undefined,
    hasExpectedLeavingDate: undefined,
    expectedLeavingDate: {
      term: '',
      year: '',
    },
  });
  const [livesInScotland, setLivesInScotland] = useState();

  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [onboardingFields, setOnboardingFields] = useState([
    'intro',
    'nickname',
    'dob',
    'additionalSupportNeeds',
    'hasLeftSchool',
    'schoolType',
    'schoolYear',
    'homeLocalAuthority',
    'isSchoolLASameAsHomeLA',
    'schoolLocalAuthority',
    'hasExpectedLeavingDate',
    'expectedLeavingDate',
    'predictedTimeline',
  ]);
  const shouldShowField = (field) => onboardingFields.indexOf(field) === currentFieldIndex;
  const currentFieldKey = onboardingFields[currentFieldIndex];

  const yesNoQuestions = ['hasLeftSchool', 'isSchoolLASameAsHomeLA', 'hasExpectedLeavingDate'];
  const shouldShowNextButton = !yesNoQuestions.find(
    (yesNoQuestion) => yesNoQuestion === currentFieldKey
  );

  const isNextButtonDisabled = () => {
    if (isSubmitting) return true;
    if (currentFieldKey === 'predictedTimeline') return false;
    switch (currentFieldKey) {
      case 'nickname':
        return false;
      case 'dob':
        return !(formData.dob.month && formData.dob.year);
      case 'expectedLeavingDate':
        return !(formData.expectedLeavingDate.term && formData.expectedLeavingDate.year);
      case 'additionalSupportNeeds':
        return !(
          formData.additionalSupportNeeds.length !== 0 ||
          formData.customAdditionalSupportNeeds.length !== 0
        );
      case 'homeLocalAuthority':
        return formData.homeLocalAuthority === undefined;
      default:
        return !formData[currentFieldKey];
    }
  };
  const nextButtonDisabled = isNextButtonDisabled();

  const nextPageOrSubmitOnboardingData = async () => {
    const currentAge = formData.dob.month && formData.dob.year && calculateAge(formData.dob);
    if (currentAge && currentAge < 12 && currentFieldIndex === onboardingFields.indexOf('dob')) {
      setDialogText({
        title: 'Your young person is under 12!',
        message:
          'This tool is designed to support transition for young people aged 12 and over. Some features may not be available until your young person reaches this stage.',
      });
      setShowAgeWarningDialog(true);
    } else if (
      formData.schoolYear === '0' &&
      currentFieldIndex === onboardingFields.indexOf('schoolYear')
    ) {
      setDialogText({
        title: 'Your young person is still in primary school!',
        message:
          'This tool is designed to support transition for young people who are a bit older. Some features may not be available until your young person reaches this stage.',
      });
      setShowAgeWarningDialog(true);
    } else if (
      currentFieldIndex === onboardingFields.indexOf('schoolLocalAuthority') &&
      RESTRICT_SIGNUP_LOCAL_AUTHORITIES &&
      !TRIAL_LOCAL_AUTHORITIES.includes(formData.schoolLocalAuthority)
    ) {
      setShowTrialUnavailableDialog(true);
    } else if (currentFieldIndex < onboardingFields.length - 1) {
      setCurrentFieldIndex(currentFieldIndex + 1);
    } else {
      submitParentOnboardingData(
        {
          nickname: formData.nickname,
          local_authority: onboardingState.localAuthorities.find(
            (la) => la.name === formData.homeLocalAuthority
          )?.id,
          birth_year: formData.dob.year,
          birth_month: formData.dob.month,
          has_left_school: formData.hasLeftSchool,
          school_local_authority: onboardingState.localAuthorities.find(
            (la) => la.name === formData.schoolLocalAuthority
          )?.id,
          school_type: formData.schoolType,
          current_school_year: formData.schoolYear,
          expected_school_leaving_year: formData.expectedLeavingDate.year,
          expected_school_leaving_term: formData.expectedLeavingDate.term,
          additional_support_needs: formData.additionalSupportNeeds,
          custom_additional_support_needs: formData.customAdditionalSupportNeeds,
          current_stage_id: currentStage.id,
        },
        {
          onSuccess: (newYoungPerson) => {
            // Once onboarding is complete, the user is navigated to the home screen based on the
            // render method of OnboardingProvider
            fetchUser();
            setSelectedYoungPersonID(newYoungPerson.id);
            setIsAddingAdditionalYoungPerson(false);
          },
        }
      );
    }
  };

  const handlePrevButtonPress = () => {
    setCurrentFieldIndex(currentFieldIndex - 1);
  };

  useEffect(() => {
    // If signups are restricted and the YP's school LA is same as home LA
    if (RESTRICT_SIGNUP_LOCAL_AUTHORITIES && formData.isSchoolLASameAsHomeLA) {
      // Ensure the home LA is within the trial areas
      if (!TRIAL_LOCAL_AUTHORITIES.includes(formData.homeLocalAuthority)) {
        setShowTrialUnavailableDialog(true);
      }
    }
  }, [formData.isSchoolLASameAsHomeLA]);

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={(currentFieldIndex * 100) / onboardingFields.length}
      />
      {isAddingAdditionalYoungPerson && (
        <PageHeader color={transitionColor} name={`Adding young person`}>
          <Button
            variant="outlined"
            onClick={() => setIsAddingAdditionalYoungPerson(false)}
            sx={{mb: 2}}>
            Cancel
          </Button>
        </PageHeader>
      )}
      <form
        className="center-content"
        onSubmit={(event) => {
          event.preventDefault();
          nextPageOrSubmitOnboardingData();
        }}>
        <Container maxWidth="md" sx={{py: 4}}>
          <AgeWarningDialog
            isVisible={showAgeWarningDialog}
            text={dialogText}
            closeDialog={() => {
              setCurrentFieldIndex(currentFieldIndex + 1);
              setShowAgeWarningDialog(false);
            }}
          />
          {shouldShowField('intro') && (
            <Box sx={{textAlign: 'center'}}>
              {!isAddingAdditionalYoungPerson && (
                <Typography variant="h2" sx={{mb: 2}}>
                  Thanks for signing up!
                </Typography>
              )}
              <Typography paragraph>
                Compass works by identifying where your young person is on their transitions
                journey. How you answer the following questions will help determine what information
                you might need.
              </Typography>
              {!isAddingAdditionalYoungPerson && (
                <Typography paragraph>
                  If you have more than one young person going through transition, you can add them
                  later from the Menu.
                </Typography>
              )}
            </Box>
          )}
          {shouldShowField('nickname') && (
            <NicknamePage
              nickname={formData.nickname}
              setNickname={(nickname) => setFormData({...formData, nickname})}
            />
          )}
          {shouldShowField('dob') && (
            <DateInputPage
              title="When was your young person born?"
              subtitle="We use this to calculate your stage in the transitions path. We just need the month and year."
              month={formData.dob.month}
              setMonth={(month) => {
                setFormData({...formData, dob: {...formData.dob, month}});
              }}
              year={formData.dob.year}
              setYear={(year) => {
                setFormData({...formData, dob: {...formData.dob, year}});
              }}
              minYear={MIN_YEAR_OF_BIRTH_PARENT_TOOL}
              maxYear={MAX_YEAR_OF_BIRTH_PARENT_TOOL}
            />
          )}
          {shouldShowField('additionalSupportNeeds') && onboardingState.additionalSupportNeeds && (
            <>
              <AdditionalSupportNeedsPage
                heading="What additional support needs does your young person have?"
                caption="Please select all that apply."
                additionalSupportNeeds={onboardingState.additionalSupportNeeds}
                selectedAdditionalSupportNeeds={formData.additionalSupportNeeds}
                toggleAdditionalSupportNeed={(name, isChecked) => {
                  if (isChecked) {
                    setFormData({
                      ...formData,
                      additionalSupportNeeds: [...formData.additionalSupportNeeds, name],
                    });
                  } else {
                    const newAdditionalSupportNeeds = formData.additionalSupportNeeds.filter(
                      (item) => item !== name
                    );
                    setFormData({
                      ...formData,
                      additionalSupportNeeds: newAdditionalSupportNeeds,
                    });
                  }
                }}
                customAdditionalSupportNeeds={formData.customAdditionalSupportNeeds}
                setCustomAdditionalSupportNeeds={(newCustomAdditionalSupportNeeds) => {
                  setFormData({
                    ...formData,
                    customAdditionalSupportNeeds: newCustomAdditionalSupportNeeds,
                  });
                }}
                otherLabel="Other"
              />
            </>
          )}
          {shouldShowField('hasLeftSchool') && (
            <HasLeftSchoolPage
              hasLeftSchool={formData.hasLeftSchool}
              setHasLeftSchool={(hasLeftSchool) => {
                setFormData({...formData, hasLeftSchool});
                if (hasLeftSchool) {
                  // If the young person has left school, don't ask the user to enter
                  // their school year or leaving date
                  const newOnboardingFields = onboardingFields.filter(
                    (field) => field !== 'schoolYear' && field !== 'hasExpectedLeavingDate'
                  );
                  setOnboardingFields(newOnboardingFields);
                } else {
                  // Otherwise, ask for school year and expected leaving date
                  const newOnboardingFields = [...onboardingFields];
                  const indexOfSchoolType = newOnboardingFields.indexOf('schoolType');
                  if (!newOnboardingFields.includes('schoolYear')) {
                    newOnboardingFields.splice(indexOfSchoolType + 1, 0, 'schoolYear');
                  }
                  const indexOfExpectedLeavingDate =
                    newOnboardingFields.indexOf('expectedLeavingDate');
                  if (!newOnboardingFields.includes('hasExpectedLeavingDate')) {
                    newOnboardingFields.splice(
                      indexOfExpectedLeavingDate,
                      0,
                      'hasExpectedLeavingDate'
                    );
                  }
                  setOnboardingFields(newOnboardingFields);
                }
                nextPageOrSubmitOnboardingData();
              }}
            />
          )}
          {shouldShowField('schoolType') && (
            <SchoolTypePage
              hasLeftSchool={formData.hasLeftSchool}
              schoolType={formData.schoolType}
              setSchoolType={(schoolType) => {
                setFormData({...formData, schoolType});
              }}
            />
          )}
          {shouldShowField('schoolYear') && (
            <SchoolYearPage
              month={formData.dob.month}
              year={formData.dob.year}
              schoolYear={formData.schoolYear}
              setSchoolYear={(schoolYear) => {
                setFormData({...formData, schoolYear});
              }}
            />
          )}
          {shouldShowField('homeLocalAuthority') && (
            <LocalAuthorityPage
              livesInScotlandQuestion={`${
                formData.hasLeftSchool && formData.schoolType !== 'Home educated' ? 'Did' : 'Does'
              } your young person live ${
                formData.schoolType === 'Home educated' ? '' : 'and go to school'
              } in Scotland?`}
              livesInScotlandCopy={
                'You can still use Compass, but some of the information we provide might not apply in your area.'
              }
              livesInScotland={livesInScotland}
              setLivesInScotland={setLivesInScotland}
              title="What local authority does your young person live in?"
              subtitle="We use this to collect information anonymously about transitions experiences across Scotland."
              localAuthority={formData.homeLocalAuthority}
              setLocalAuthority={(homeLocalAuthority) => {
                setFormData({...formData, homeLocalAuthority});
                if (homeLocalAuthority === null) {
                  const newOnboardingFields = onboardingFields.filter(
                    (field) =>
                      field !== 'isSchoolLASameAsHomeLA' && field !== 'schoolLocalAuthority'
                  );
                  setOnboardingFields(newOnboardingFields);
                }
              }}
            />
          )}
          <TrialUnavailableInLADialog
            isVisible={showTrialUnavailableDialog}
            closeDialog={() => setShowTrialUnavailableDialog(false)}
          />
          {shouldShowField('isSchoolLASameAsHomeLA') && (
            <IsSchoolLASameAsHomeLAPage
              hasLeftSchool={formData.hasLeftSchool}
              schoolType={formData.schoolType}
              isSchoolLASameAsHomeLA={formData.isSchoolLASameAsHomeLA}
              setIsSchoolLASameAsHomeLA={(isSchoolLASameAsHomeLA) => {
                if (isSchoolLASameAsHomeLA) {
                  // If it's the same, set schoolLocalAuthority to match and skip that screen
                  setFormData({
                    ...formData,
                    isSchoolLASameAsHomeLA,
                    schoolLocalAuthority: formData.homeLocalAuthority,
                  });
                  const newOnboardingFields = onboardingFields.filter(
                    (field) => field !== 'schoolLocalAuthority'
                  );
                  setOnboardingFields(newOnboardingFields);
                } else {
                  // Otherwise, update formData and show the schoolLocalAuthority screen
                  setFormData({
                    ...formData,
                    isSchoolLASameAsHomeLA,
                  });
                  const newOnboardingFields = [...onboardingFields];
                  const indexOfIsSchoolLASameAsHomeLA =
                    newOnboardingFields.indexOf('isSchoolLASameAsHomeLA');
                  if (!newOnboardingFields.includes('schoolLocalAuthority')) {
                    newOnboardingFields.splice(
                      indexOfIsSchoolLASameAsHomeLA + 1,
                      0,
                      'schoolLocalAuthority'
                    );
                    setOnboardingFields(newOnboardingFields);
                  }
                }
                nextPageOrSubmitOnboardingData();
              }}
            />
          )}
          {shouldShowField('schoolLocalAuthority') && !formData.isSchoolLASameAsHomeLA && (
            <LocalAuthorityPage
              livesInScotland
              title={`What local authority ${
                formData.hasLeftSchool ? `did` : `does`
              } your young person go to school in?`}
              subtitle="We use this to collect information anonymously about transitions experiences across Scotland."
              localAuthority={formData.schoolLocalAuthority}
              setLocalAuthority={(schoolLocalAuthority) =>
                setFormData({...formData, schoolLocalAuthority})
              }
            />
          )}
          {shouldShowField('hasExpectedLeavingDate') && (
            <HasExpectedLeavingDatePage
              hasExpectedLeavingDate={formData.hasExpectedLeavingDate}
              setHasExpectedLeavingDate={(hasExpectedLeavingDate) => {
                if (hasExpectedLeavingDate) {
                  // If user has expected leaving date, clear our state
                  // Then show the leaving date screen
                  setFormData({
                    ...formData,
                    hasExpectedLeavingDate,
                    expectedLeavingDate: {
                      term: '',
                      year: '',
                    },
                  });
                  const newOnboardingFields = [...onboardingFields];
                  const indexOfHasExpectedLeavingDate =
                    newOnboardingFields.indexOf('hasExpectedLeavingDate');
                  if (!newOnboardingFields.includes('expectedLeavingDate')) {
                    newOnboardingFields.splice(
                      indexOfHasExpectedLeavingDate + 1,
                      0,
                      'expectedLeavingDate'
                    );
                    setOnboardingFields(newOnboardingFields);
                  }
                } else {
                  // Otherwise calculate an expected one, skip expected leaving date screen
                  calculatePotentialExpectedLeavingDates(
                    formData.dob.month,
                    formData.dob.year,
                    formData.schoolYear,
                    !formData.hasLeftSchool //  if hasn't left school yet, hide past dates
                  ).then((possibleLeavingDates) => {
                    const earliestPossibleLeavingDate = possibleLeavingDates[0];
                    setFormData({
                      ...formData,
                      hasExpectedLeavingDate,
                      expectedLeavingDate: earliestPossibleLeavingDate,
                    });
                  });
                  const newOnboardingFields = onboardingFields.filter(
                    (field) => field !== 'expectedLeavingDate'
                  );
                  setOnboardingFields(newOnboardingFields);
                }
                nextPageOrSubmitOnboardingData();
              }}
            />
          )}
          {shouldShowField('expectedLeavingDate') && (
            <ExpectedLeavingDatePage
              hasLeftSchool={formData.hasLeftSchool}
              dob={formData.dob}
              schoolYear={formData.schoolYear}
              expectedLeavingDate={formData.expectedLeavingDate}
              setExpectedLeavingDate={(expectedLeavingDate) => {
                setFormData({
                  ...formData,
                  expectedLeavingDate,
                });
              }}
            />
          )}
          {shouldShowField('predictedTimeline') && (
            <PredictedTimelinePage
              expectedLeavingDate={formData.expectedLeavingDate}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
            />
          )}
          <div className={currentFieldIndex > 0 ? 'register-buttons' : 'form-button'}>
            {currentFieldIndex > 0 && (
              <Button variant="outlined" onClick={handlePrevButtonPress}>
                Previous
              </Button>
            )}
            {currentFieldIndex === 0 ? (
              <Button variant="contained" color="secondary" type="submit" autoFocus>
                Continue
              </Button>
            ) : (
              shouldShowNextButton && (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={nextButtonDisabled}
                  autoFocus={currentFieldIndex === onboardingFields.length - 1}>
                  {currentFieldIndex < onboardingFields.length - 1 ? 'Next' : 'Finish'}
                </Button>
              )
            )}
          </div>
        </Container>
      </form>
    </>
  );
};

export default Onboarding;
