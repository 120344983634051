import React from 'react';
import {Dialog, Button, DialogContent, DialogTitle, Typography, DialogActions} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import {useEvaluation} from '../Evaluation/EvaluationProvider';

const ParentEvaluationPromptDialog = ({isVisible, closeDialog}) => {
  const {evaluationState, isLoading, incrementSnoozeCount} = useEvaluation();
  const navigate = useNavigate();

  const handleSnooze = () => {
    closeDialog();
    incrementSnoozeCount();
  };

  const handleStart = async () => {
    closeDialog();
    navigate('/feedback/about');
  };

  let snoozeMessage;
  if (evaluationState.remainingSnoozeCount > 0) {
    snoozeMessage = `You have ${evaluationState.remainingSnoozeCount} snooze${
      evaluationState.remainingSnoozeCount > 1 ? 's' : ''
    } remaining`;
  } else {
    snoozeMessage = '(You have used all of your snoozes)';
  }

  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Time to tell us about your transitions experience!</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          We would like to hear your thoughts on how things are going in the transitions process.
        </Typography>
        <Typography paragraph>
          If now is not a good time, you can snooze this message and we will check in again later.
        </Typography>
        <Typography paragraph>{snoozeMessage}</Typography>
        <DialogActions sx={{justifyContent: 'space-around'}}>
          {evaluationState.remainingSnoozeCount > 0 && (
            <Button variant="outlined" color="secondary" onClick={handleSnooze}>
              Snooze
            </Button>
          )}
          <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleStart}>
            Start
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ParentEvaluationPromptDialog;
