import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Box, Card, CardActionArea, Typography} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';

import {useTransition} from '../TransitionProvider';
import {categoryIcons} from '../../Util/constants';
import LinearProgressIndicator from '../../Util/LinearProgressIndicator';
import KeyActionBadge from '../../Parent/KeyActionBadge';

const CategoryTile = ({category}) => {
  const {transitionState} = useTransition();
  const navigate = useNavigate();
  const theme = useTheme();
  const {transitionColor} = theme.palette;

  const youngPersonKeyActions = transitionState?.keyActions?.[category.id] ?? [];
  const completedYoungPersonKeyActions = youngPersonKeyActions.filter(
    (keyAction) => !!keyAction.completed
  );
  const completedKeyActionsCount = completedYoungPersonKeyActions.length;
  const percentageProgress =
    (completedYoungPersonKeyActions.length / youngPersonKeyActions.length) * 100;
  const incompleteActionsCount =
    youngPersonKeyActions.length - completedYoungPersonKeyActions.length;

  return (
    <Card sx={{borderRadius: 5, display: 'flex', flexDirection: 'column'}}>
      <CardActionArea
        onClick={() => navigate({pathname: `/${category.id}/action-list`})}
        sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Box sx={{backgroundColor: transitionColor, p: 2, textAlign: 'center', width: '100%'}}>
          <img src={categoryIcons[category.name]} width={42} height={42} alt="" />
        </Box>
        <Box sx={{pt: 2, px: 2, flex: 1}}>
          <Typography variant="h4" gutterBottom>
            {category.name}
          </Typography>
          <Typography variant="body2" paragraph>
            {category.description}
          </Typography>
        </Box>
        <div className="card-footer">
          <Box sx={{mb: 2}}>
            <LinearProgressIndicator
              percentageProgress={percentageProgress}
              outerColor={
                incompleteActionsCount > 0 ? theme.palette.secondary.pale : theme.palette.mono.pale
              }
              innerColor={
                incompleteActionsCount > 0 ? theme.palette.secondary.main : theme.palette.mono.light
              }
            />
          </Box>
          <div className="card-footer-row">
            <DoneIcon sx={{mr: 1.5}} />
            <Typography variant="body2">
              {percentageProgress === 100 ? 'All Done' : `${completedKeyActionsCount} Completed`}
            </Typography>
          </div>
          <div className="card-footer-row">
            {incompleteActionsCount > 0 && (
              <>
                <KeyActionBadge style={{marginRight: 12}} />
                <Typography variant="body2">
                  {incompleteActionsCount} Thing{incompleteActionsCount > 1 && 's'} to Do
                </Typography>
              </>
            )}
            <div className="card-footer-row-arrow">
              View
              <ArrowForwardIcon
                sx={{ml: 1}}
                color={incompleteActionsCount > 0 ? 'secondary' : undefined}
              />
            </div>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default CategoryTile;
