import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Container, Typography, Link, Divider} from '@mui/material';

import {useAuth} from '../Auth/AuthProvider';
import LandingVisual from '../img/landing-page.svg';
import openQuoteIcon from '../img/quote-open.svg';
import closeQuoteIcon from '../img/quote-close.svg';
import AccountDeletedDialog from '../Account/AccountDeletedtDialog';
import {sectionIcons} from '../Util/constants';
import whoIsCompassForIcon from '../img/tell-me-more-landing.svg';
import {YP_BASE_URL} from '../Util/constants';
import LandingPageFeature from '../LandingPageFeature';

const Testimonial = ({quote, credit}) => (
  <Box
    sx={{
      p: 4,
      my: 2,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary.dark',
      borderRadius: 2.5,
    }}>
    <Typography variant="h4" component="blockquote" sx={{flexGrow: 1}}>
      "{quote}"
    </Typography>
    <Typography variant="subtitle1" fontStyle={'italic'} sx={{mt: 2}} textAlign={'right'}>
      - {credit}
    </Typography>
  </Box>
);

const Start = () => {
  const theme = useTheme();
  const {transitionColor, feedbackColor, glossaryColor} = theme.palette;
  const {transitionTopicsIcon, feedbackIcon, glossaryIcon} = sectionIcons;
  const {authState, dispatch} = useAuth();
  const [shouldShowDeletedDialog, setShouldShowDeletedDialog] = useState(false);

  useEffect(() => {
    if (authState.isDeleted) {
      setShouldShowDeletedDialog(true);
    } else {
      setShouldShowDeletedDialog(false);
    }
  }, [authState.isDeleted]);

  return (
    <>
      {authState.isDeleted && (
        <AccountDeletedDialog
          isVisible={shouldShowDeletedDialog}
          closeDialog={async () => {
            await dispatch({type: 'SET_IS_DELETED', payload: false});
            setShouldShowDeletedDialog(false);
          }}
        />
      )}
      <Box sx={{py: 4, backgroundColor: {md: 'primary.dark'}}}>
        <Container
          maxWidth="md"
          disableGutters
          sx={{
            px: {md: 2, lg: 4},
            display: {md: 'flex'},
            alignItems: 'center',
            flexDirection: {md: 'row-reverse'},
          }}>
          <Box
            component="img"
            src={LandingVisual}
            alt=""
            sx={{
              display: 'block',
              width: '100%',
              background: `linear-gradient(0deg, ${theme.palette.primary.dark} 33%, transparent 0)`,
              height: {xs: 192, sm: 260, md: 360},
            }}
          />
          <Box
            sx={{
              color: 'white',
              backgroundColor: 'primary.dark',
              textAlign: {xs: 'center', md: 'left'},
              pb: {xs: 4},
            }}>
            <Typography variant="h1" gutterBottom sx={{fontSize: 48}}>
              Take control of transitions
            </Typography>
            <Typography variant="h3" component="div" paragraph>
              Guidance tailor-made for you
            </Typography>
            <Box sx={{mb: 2}}>
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to="/your-data"
                sx={{mr: 1, color: 'white'}}>
                Sign up
              </Button>
              <Button variant="outlined" component={RouterLink} to="/login" sx={{color: 'white'}}>
                Log In
              </Button>
            </Box>
            <Box>
              Aged 14-25? Try{' '}
              <Link
                sx={{color: 'white', textDecorationColor: 'white'}}
                href={YP_BASE_URL}
                underline="always">
                Compass for Young People
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="md" sx={{py: 6}}>
        <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
          <Box component="img" src={whoIsCompassForIcon} alt="" sx={{height: {xs: 80, md: 120}}} />
          <Typography variant="h2">Who is Compass for?</Typography>
        </Box>
        <Typography paragraph>
          Compass is for <b>parents and carers</b> of all young people in Scotland who need support
          as they make the transition to young adult life.
        </Typography>
        <Typography paragraph>
          Compass can help if your young person is 14-25 years old and has:
        </Typography>
        <ul>
          <li>
            <Typography>
              Any Additional Support Need (e.g. disability, physical or mental health difficulties)
            </Typography>
          </li>
          <li>
            <Typography>Experience of care (young carer / care experienced)</Typography>
          </li>
        </ul>
        <Divider sx={{my: 4}} />
        <Typography variant="h3" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Typography paragraph>
          For more information about Compass, you can view our{' '}
          <Link
            href="https://www.pn2p.scot/compass/#:~:text=as%20a%20pdf-,FAQs,-Who%20is%20Compass"
            target="_blank"
            rel="noreferrer">
            Frequently Asked Questions
          </Link>
        </Typography>
      </Container>
      <Box sx={{backgroundColor: 'primary.pale', py: 6, color: 'black'}}>
        <Container maxWidth="md">
          <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
            <Box component="img" src={openQuoteIcon} alt="" sx={{height: {xs: 28, md: 56}}} />
            <Typography variant="h2">What parents say about Compass</Typography>
            <Box component="img" src={closeQuoteIcon} alt="" sx={{height: {xs: 28, md: 56}}} />
          </Box>
          <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2}}>
            <Testimonial
              quote="Any parent would find it helpful"
              credit="Jennifer, Parent, Falkirk"
            />
            <Testimonial
              quote="Because everything's in one place, it feels like I have more control"
              credit="David, Parent, Falkirk"
            />
          </Box>
        </Container>
      </Box>
      <Container maxWidth="md" sx={{pt: 6}}>
        <Typography variant="h2" gutterBottom>
          Why Compass?
        </Typography>
        <LandingPageFeature
          iconColor={transitionColor}
          iconSrc={transitionTopicsIcon}
          title="Untangle transitions"
          description="Compass guides you through what you need to know and creates a personal 'to do' list to keep you on track."
        />
        <LandingPageFeature
          iconColor={feedbackColor}
          iconSrc={feedbackIcon}
          title="Get your voice heard"
          description="Tell your local authority anonymously about your experiences to improve the process for everyone. Compass adds your responses to others so no one can identify you."
        />
        <LandingPageFeature
          iconColor={glossaryColor}
          iconSrc={glossaryIcon}
          title="Bust the jargon"
          description="The Transitions A-Z helps you understand terms you come across."
        />
      </Container>
      <Container maxWidth="md" sx={{py: 4}}>
        <Divider aria-hidden="true" sx={{mb: 4, backgroundColor: 'primary.pale'}} />
        <Typography paragraph>
          View Compass{` `}
          <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
            Privacy Notice
          </Link>
        </Typography>
      </Container>
    </>
  );
};

export default Start;
