import React, {useState} from 'react';
import {Dialog, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import {useYoungPerson} from '../Parent/YoungPersonProvider';
import {FEEDBACK_STRINGS} from '../Util/constants';

const EditUnavailableDialog = ({closeDialog, fieldName}) => {
  const {youngPerson, deleteYoungPerson} = useYoungPerson();
  const [deleteTextInputValue, setDeleteTextInputValue] = useState('');

  const userEnteredDelete = deleteTextInputValue === 'DELETE';

  const handleDeleteYoungPerson = () => {
    if (userEnteredDelete) {
      deleteYoungPerson(youngPerson.id);
    }
  };

  const explanationParagraph =
    fieldName === 'date of birth'
      ? 'If you have made a small error during sign up, we suggest ignoring this mistake for the trial. If you have made a more significant error which could impact your timeline or expected leaving date, we will need to delete the information about your young person and ask you to start again.'
      : 'If you need to update this, we will need to delete the information about your young person and ask you to start again.';

  return (
    <Dialog
      open={fieldName !== undefined}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Edit Not Available</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Editing your young person's {fieldName} is currently unavailable.
        </Typography>
        <Typography paragraph>{explanationParagraph}</Typography>
        <Typography variant="h4" component="div" gutterBottom>
          Choosing to start again means:
        </Typography>
        <Typography paragraph>
          1. Your answers to the 'What now?' questions will be deleted.
        </Typography>
        <Typography paragraph>
          2. Your progress in the 'Things to do' lists in Transitions Topics will be deleted.
        </Typography>
        <Typography paragraph>
          3. You will be taken back to the questions we asked when you signed up to the application.
        </Typography>
        <Typography paragraph>
          Your previous responses about your transitions experience will be unaffected. You will
          still be able to view these in '{FEEDBACK_STRINGS.parent.feedbackHistoryTitle}'.
        </Typography>
        <Typography paragraph>
          If you would like to delete your young person’s information from the application, please
          type <b>DELETE</b> into the box below and click the delete button.
        </Typography>
        <input
          type="text"
          required
          value={deleteTextInputValue}
          onChange={(event) => setDeleteTextInputValue(event.target.value)}
        />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setDeleteTextInputValue('');
              closeDialog();
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteYoungPerson}
            disabled={!userEnteredDelete}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditUnavailableDialog;
