import React from 'react';
import star from '../img/star.svg';

const KeyActionBadge = ({style}) => {
  return (
    <div
      className="badge key-action-badge"
      style={{
        ...style,
      }}>
      <img src={star} alt="" />
    </div>
  );
};

export default KeyActionBadge;
