import {useMutation} from '@tanstack/react-query';
import axios from 'axios';

const useSubmitParentOnboarding = () => {
  return useMutation({
    mutationKey: ['submit-parent-onboarding'],
    mutationFn: async (data) =>
      await axios.post('/api/onboarding/submit-onboarding', data).then((res) => res.data),
  });
};

export default useSubmitParentOnboarding;
