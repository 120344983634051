import React from 'react';
import {Button, Container} from '@mui/material';

const UnlockedItemsNotification = ({
  categoriesWithNewKeyActions,
  numUnlockedTransitionQuestions,
  clearUnlockedItems,
}) => {
  return (
    <div className="center-content">
      <Container maxWidth="sm">
        {categoriesWithNewKeyActions.length > 0 && (
          <div className="header-5 centre-aligned">
            I've added something for you to explore in
            {categoriesWithNewKeyActions.map((category, index) => (
              <div key={index}>{`${category.name}${
                index === categoriesWithNewKeyActions.length - 1
                  ? ' in your Transition Topics.'
                  : index === categoriesWithNewKeyActions.length - 2
                  ? ' and'
                  : ','
              }`}</div>
            ))}
          </div>
        )}
        {numUnlockedTransitionQuestions === 1 && (
          <div className="header-5" style={{marginTop: '10px'}}>
            You have a new question to answer, it will be asked next.
          </div>
        )}
        {numUnlockedTransitionQuestions > 1 && (
          <div className="header-5" style={{marginTop: '10px'}}>
            You have new questions to answer, they will be asked next.
          </div>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={clearUnlockedItems}
          sx={{float: 'right'}}>
          Next
        </Button>
      </Container>
    </div>
  );
};

export default UnlockedItemsNotification;
