import React from 'react';
import {Typography} from '@mui/material';

const NicknamePage = ({nickname, setNickname}) => {
  return (
    <>
      <Typography variant="h3" paragraph>
        Give your young person a nickname
      </Typography>
      <Typography paragraph>
        This can be helpful if you&apos;re adding multiple young people.
      </Typography>
      <input
        name="nickname"
        autoFocus
        type="text"
        value={nickname ?? ''}
        onChange={(event) => setNickname(event.target.value)}
        placeholder="Nickname"
      />
    </>
  );
};

export default NicknamePage;
