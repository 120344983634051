import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import {useNavigate} from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Avatar, Button, ListItemAvatar, ListItemButton, ListSubheader} from '@mui/material';
import {Box, Container} from '@mui/system';
import {Add} from '@mui/icons-material';
import Brightness4Icon from '@mui/icons-material/Brightness4';

import {useAuth} from '../Auth/AuthProvider';
import {FEEDBACK_STRINGS, sectionIcons, DIALOG_URL_PARAMS, MONTHS} from '../Util/constants';

import useDialogQuery from '../Util/useDialogQuery';

import HomeIcon from '../img/MenuIcons/Home.svg';
import parentLogo from '../img/logo/logo_parent.svg';
import parentLogoDark from '../img/logo/logo_parent_dark.svg';
import MenuIcon from '../img/MenuIcons/Menu.svg';
import TransitionTopicsIcon from '../img/MenuIcons/TransitionTopics.svg';
import FeedbackIcon from '../img/MenuIcons/Feedback-parent.svg';
import AccountIcon from '../img/MenuIcons/Account.svg';
import PrivacyIcon from '../img/MenuIcons/Privacy.svg';
import ContactUsIcon from '../img/MenuIcons/ContactUs.svg';
import LogoutIcon from '../img/MenuIcons/Logout.svg';
import {useYoungPerson} from './YoungPersonProvider';
import {getYoungPersonDisplayName} from '../Util/helpers';
import {useDarkModeTheme} from '../DarkModeThemeProvider';

const navIconStyle = {display: 'flex', flexDirection: 'column', mx: 0.5};

const NavBar = () => {
  const {isDarkMode, setIsDarkMode} = useDarkModeTheme();
  const {authState, showLogoutDialog} = useAuth();
  const {
    youngPeople,
    youngPerson,
    setSelectedYoungPersonID,
    setIsAddingAdditionalYoungPerson,
    isAddingAdditionalYoungPerson,
  } = useYoungPerson();
  const {openDialog} = useDialogQuery();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerIsOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerIsOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="md">
          {authState.isAuthenticated && (
            <Toolbar disableGutters>
              <Box className="compass-logo-container" sx={{flex: 0, ml: 0, mr: 2}}>
                <button className="button-reset" onClick={() => navigate('/')} id="home-button">
                  <img
                    src={isDarkMode ? parentLogoDark : parentLogo}
                    id="compass-nav-logo"
                    height={25}
                    alt="Compass Homepage"
                  />
                </button>
              </Box>
              <Box sx={{flex: 1}} />
              <IconButton
                id="glossary-nav-button"
                edge="start"
                color="inherit"
                onClick={() => openDialog(DIALOG_URL_PARAMS.GLOSSARY)}
                sx={navIconStyle}>
                <img src={sectionIcons.glossaryIcon} height={24} alt="" />
                <div style={{fontSize: 11, letterSpacing: 0.33}}>Transitions A-Z</div>
              </IconButton>
              {youngPerson && (
                <IconButton
                  id="young-person-nav-button"
                  edge="start"
                  color="inherit"
                  onClick={() => navigate(`/young-person/${youngPerson.id}`)}
                  sx={navIconStyle}
                  size="small">
                  <Avatar sx={{width: 24, height: 24}} />
                  <div style={{fontSize: 11, letterSpacing: 0.33}}>
                    {getYoungPersonDisplayName(youngPerson)}
                  </div>
                </IconButton>
              )}
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDrawerOpen}
                sx={navIconStyle}
                id="menu">
                <img src={MenuIcon} height={24} alt="" />
                <div style={{fontSize: 11, letterSpacing: 0.33}}>Menu</div>
              </IconButton>
            </Toolbar>
          )}
        </Container>
      </AppBar>
      <Drawer anchor={'right'} open={drawerIsOpen} onClose={handleDrawerClose}>
        <div role="presentation" onClick={handleDrawerClose} style={{minWidth: 250}}>
          <div className="nav-bar-header">
            <h2 className="header-4">Menu</h2>
            <IconButton onClick={() => setIsDarkMode(!isDarkMode)} sx={{ml: 'auto'}}>
              <Brightness4Icon />
            </IconButton>
          </div>
          <Divider aria-hidden="true" />
          {youngPerson && youngPeople && (
            <>
              <List subheader={<ListSubheader disableSticky>My Young People</ListSubheader>}>
                {youngPeople.map((eachYoungPerson) => (
                  <ListItemButton
                    key={`young-person-${eachYoungPerson.id}`}
                    selected={youngPerson.id === eachYoungPerson?.id}
                    onClick={() => setSelectedYoungPersonID(eachYoungPerson.id)}>
                    <ListItemAvatar>
                      <Avatar />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        eachYoungPerson.nickname ??
                        `${MONTHS[eachYoungPerson.birth_month].slice(0, 3)} ${
                          eachYoungPerson.birth_year
                        }`
                      }
                    />
                    <ArrowForwardIosIcon />
                  </ListItemButton>
                ))}
                <ListItem sx={{justifyContent: 'flex-end'}}>
                  <Button
                    onClick={() => setIsAddingAdditionalYoungPerson(true)}
                    variant="outlined"
                    endIcon={<Add />}>
                    Add new young person
                  </Button>
                </ListItem>
              </List>
              <Divider aria-hidden="true" />
            </>
          )}
          <List>
            {authState.user.hasOnboarded && (
              <>
                <ListItemButton
                  disabled={isAddingAdditionalYoungPerson}
                  onClick={() => {
                    navigate('/');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={HomeIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Home'} />
                </ListItemButton>
                <ListItemButton
                  disabled={isAddingAdditionalYoungPerson}
                  onClick={() => {
                    navigate('/transition-topics');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={TransitionTopicsIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Transition Topics'} />
                </ListItemButton>
                <ListItemButton
                  disabled={isAddingAdditionalYoungPerson}
                  onClick={() => {
                    navigate('/feedback');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={FeedbackIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={FEEDBACK_STRINGS.parent.feedbackTitle} />
                </ListItemButton>
                <ListItemButton
                  disabled={isAddingAdditionalYoungPerson}
                  onClick={() => {
                    navigate('/my-account');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={AccountIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'My Account'} />
                </ListItemButton>
                <ListItemButton
                  disabled={isAddingAdditionalYoungPerson}
                  onClick={() => {
                    navigate('/privacy');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={PrivacyIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Privacy Notice'} />
                </ListItemButton>
                <ListItemButton
                  disabled={isAddingAdditionalYoungPerson}
                  onClick={() => {
                    navigate('/contact-us');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={ContactUsIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Contact Us'} />
                </ListItemButton>
              </>
            )}

            <ListItemButton
              onClick={() => {
                handleDrawerClose();
                showLogoutDialog();
              }}>
              <ListItemIcon>
                <img src={LogoutIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>

            {authState.user.hasOnboarded && (
              <>
                <Divider />
                <ListItem>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      navigate('/crisis');
                      handleDrawerClose();
                    }}>
                    Emergency Information
                  </Button>
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
