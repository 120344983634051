import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';

import {MONTHS} from '../Util/constants';
import AccountItem from '../Account/AccountItem';
import {useOnboarding} from '../Onboarding/OnboardingProvider';
import {useYoungPerson} from './YoungPersonProvider';
import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import EditUnavailableDialog from '../Account/EditUnavailableDialog';
import {Container, Typography} from '@mui/material';

const YoungPerson = () => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  const [disallowedEditFieldName, setDisallowedEditFieldName] = useState(undefined);
  const {youngPersonID} = useParams();
  const {youngPeople} = useYoungPerson();
  const youngPerson = youngPeople.find(({id}) => id === parseInt(youngPersonID));
  const {onboardingState} = useOnboarding();
  const navigate = useNavigate();
  return (
    <>
      <EditUnavailableDialog
        closeDialog={() => setDisallowedEditFieldName(undefined)}
        fieldName={disallowedEditFieldName}
      />
      <PageHeader
        color={dark}
        name={'My Young Person'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
        backTo="/my-account"
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <Typography variant="h3" component="h2" gutterBottom>
          Young Person&apos;s Information
        </Typography>
        <AccountItem heading="Nickname:" onClick={() => navigate('edit/nickname')}>
          <div className="body2">{youngPerson.nickname ?? ''}</div>
        </AccountItem>
        <AccountItem
          heading="Date of Birth:"
          onClick={() => {
            setDisallowedEditFieldName('date of birth');
          }}>
          {youngPerson.birth_month && youngPerson.birth_year && (
            <div className="body2">{`${MONTHS[youngPerson.birth_month]}, ${
              youngPerson.birth_year
            }`}</div>
          )}
        </AccountItem>
        <AccountItem heading="Additional Needs:" onClick={() => navigate('edit/additional-needs')}>
          {youngPerson.additional_support_needs &&
            youngPerson.additional_support_needs.map((item) => (
              <div key={`additional-need-${item.id}`} className="body2">
                {item.name}
              </div>
            ))}
          {youngPerson.custom_additional_support_needs &&
            youngPerson.custom_additional_support_needs.map((need, index) => (
              <div key={`custom-additional-need-${index}`} className="body2">
                {need.name}
              </div>
            ))}
        </AccountItem>
        <AccountItem heading="Type of School:" onClick={() => navigate('edit/school-type')}>
          {youngPerson.school_type && <div className="body2">{youngPerson.school_type}</div>}
        </AccountItem>
        <AccountItem
          heading="School Year:"
          onClick={() => {
            setDisallowedEditFieldName('school year');
          }}>
          {youngPerson.current_school_year !== undefined && onboardingState.schoolYears && (
            <div className="body2">
              {onboardingState.schoolYears[youngPerson.current_school_year]}
            </div>
          )}
        </AccountItem>
        <AccountItem
          heading="Residential Local Authority:"
          onClick={() => navigate('edit/residential-local-authority')}>
          <div className="body2">
            {youngPerson.home_local_authority?.name ?? 'Does not live in Scotland'}
          </div>
        </AccountItem>
        {youngPerson.home_local_authority ? (
          <AccountItem
            heading="Educational Local Authority:"
            onClick={() => navigate('edit/educational-local-authority')}>
            {youngPerson.school_local_authority && (
              <div className="body2">{youngPerson.school_local_authority.name}</div>
            )}
          </AccountItem>
        ) : null}
        <AccountItem
          heading="Expected Leaving Date:"
          onClick={() => {
            setDisallowedEditFieldName('expected leaving date');
          }}>
          {youngPerson.expected_school_leaving_term && youngPerson.expected_school_leaving_year && (
            <div className="body2">{`${youngPerson.expected_school_leaving_term}, ${youngPerson.expected_school_leaving_year}`}</div>
          )}
        </AccountItem>
      </Container>
    </>
  );
};

export default YoungPerson;
