import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Typography, Divider, Box, Container} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {useEvaluation} from '../Evaluation/EvaluationProvider';
import {longDateFormatter, FEEDBACK_STRINGS} from '../Util/constants';
import PreviousEvaluationQuestionResponse from './PreviousEvaluationQuestionResponse';
import EvaluationHistoryIcon from '../img/EvaluationHistoryLight.svg';
import PageHeader from '../Util/PageHeader';
import ParentCurrentYoungPersonSwitcher from './ParentCurrentYoungPersonSwitcher';

const PreviousEvaluation = () => {
  const theme = useTheme();
  const {feedbackColor} = theme.palette;
  const {evaluationId} = useParams();

  const {evaluationState, fetchHistory} = useEvaluation();
  const [currentEvaluation, setCurrentEvaluation] = useState(undefined);
  const [selectedThemeName, setSelectedThemeName] = useState(undefined);

  useEffect(() => {
    const fetchEvaluationHistory = async () => await fetchHistory();
    fetchEvaluationHistory();
  }, []);

  useEffect(() => {
    if (evaluationState.evaluations) {
      setCurrentEvaluation(
        evaluationState.evaluations.find((evaluation) => evaluation.id.toString() === evaluationId)
      );
    }
  }, [evaluationState]);

  return (
    <>
      <PageHeader
        color={feedbackColor}
        name={FEEDBACK_STRINGS.parent.feedbackHistoryTitle}
        icon={EvaluationHistoryIcon}
        backTo="/feedback/history"
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <ParentCurrentYoungPersonSwitcher verb="viewing" />
        {currentEvaluation && (
          <div className="previous-eval-info centre-aligned">
            <Typography variant="h3" gutterBottom>
              {longDateFormatter.format(new Date(currentEvaluation.completed_at))}
            </Typography>
            <Typography variant="body2" className="light-grey">
              {currentEvaluation.stage.name}
            </Typography>
          </div>
        )}
        <Divider aria-hidden="true" />
        {evaluationState.responsesByThemeName ? (
          Object.keys(evaluationState.responsesByThemeName).map((themeName) => {
            return (
              <div
                key={themeName}
                onClick={() => {
                  if (selectedThemeName !== themeName) {
                    setSelectedThemeName(themeName);
                  } else {
                    setSelectedThemeName(undefined);
                  }
                }}>
                <button className="button-reset previous-eval-theme">
                  <Typography
                    variant="h4"
                    color={selectedThemeName === themeName ? 'secondary' : 'mono'}>
                    {themeName}
                  </Typography>
                  {selectedThemeName === themeName ? (
                    <KeyboardArrowUpIcon color="secondary" />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </button>
                <Divider aria-hidden="true" />
                {selectedThemeName === themeName &&
                  evaluationState.responsesByThemeName[themeName][evaluationId].map((response) => (
                    <PreviousEvaluationQuestionResponse key={response.id} response={response} />
                  ))}
              </div>
            );
          })
        ) : (
          <div>Loading...</div>
        )}
        {currentEvaluation?.other_comments && (
          <Box sx={{p: 2, my: 2}}>
            <Typography variant="h4" gutterBottom>
              You also said...
            </Typography>
            <Typography variant="subtitle1">"{currentEvaluation?.other_comments}"</Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default PreviousEvaluation;
